// Example mapping table edit used in Summary tab
import dayjs from 'dayjs';
import React, { Component, Fragment } from 'react';
import { CandidateType } from '../models/CandidateType';
import { Verb } from '../models/Verb';
import { Candidates } from '../models/Candidates';
import { FullSentenceDefinition } from '../models/FullSentenceDefinition';
import TextOption from '../models/TextOption';
import Comment from '../models/Comment';
import dataService from '../services/data.service';
import { Example } from '../models/Example';
import { Suggestion } from '../models/Suggestion';

interface ExampleMapEditProps {
  verb: Verb;
  jpExamples: string[];
  enExamples: string[];
  suggestions: string[];
  onJpExampleChange: (value: string, index: number) => void;
  onEnExampleChange: (value: string, index: number) => void;
  onTranslationSuggestionChange: (value: string, index: number) => void;
}

// Shown in Summary tab
// Jp: static text with ruby + textarea
// En: textarea + suggestion text area
// Stage 3: Just Japanese examples - non-editable
// Stage 4:
// Stage 5:
// Stage 7: Jp + En, both non-editable
// Stage 8: Jp + En + suggestion
// Stage 9: All non-editable
class ExampleMapEdit extends Component<ExampleMapEditProps> {
  constructor(props: ExampleMapEditProps) {
    super(props);
  }

  render() {
    const rows = [];
    for (let i = 0; i < 4; i++) {
      rows.push(
        <tr key={i}>
          <td className="uk-width-auto"><span className="uk-label">{`#${i+1}`}</span></td>
          <td className={this.props.verb.stage === 3 ? 'uk-width-expand' : 'uk-width-1-2'}>
            <div
              dangerouslySetInnerHTML={{__html: this.props.jpExamples[i] || 'No example selected'}}
              style={{height: '120px'}}
            >
            </div>
            <textarea
              className="uk-textarea"
              style={{height: '120px'}}
              value={this.props.jpExamples[i]}
              disabled={this.props.verb.stage === 3 || this.props.verb.stage === 7}
              onChange={ e => this.props.onJpExampleChange(e.target.value, i) }
            >
            </textarea>
          </td>
          {
          this.props.verb.stage > 3
          &&
          <td className="uk-width-1-2">
            <textarea
              className="uk-textarea"
              style={{height: '120px'}}
              value={this.props.enExamples[i] }
              disabled={this.props.verb.stage === 7}
              onChange={ e => this.props.onEnExampleChange(e.target.value, i) }
            >
            </textarea>
            {
            this.props.verb.stage === 8
            ?
            <textarea
              className="uk-textarea"
              style={{height: '120px'}}
              value={this.props.suggestions[i]}
              onChange={ e => this.props.onTranslationSuggestionChange(e.target.value, i) }
              placeholder="Suggestion"
            >
            </textarea>
            :
            <div
            style={{height: '120px'}}
            >
            </div>
            }
          </td>
          }
      </tr>
      )
    }

    return (
      <table className="uk-table">
        <tbody>
        {rows}
        </tbody>
      </table>
    );
  }
}

export default ExampleMapEdit;
