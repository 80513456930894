import dayjs from 'dayjs';
import uuidv4 from 'uuid/v4';
import React, { Component, Fragment, ChangeEvent } from 'react';

import { Verb } from '../models/Verb';
import { Message } from '../models/Message';
import DataService from '../services/data.service';
const UIkit = require('uikit');

interface MessagePanelProps {
  messages: Message[];
  onSaveMessage: (value: string) => void;
  onDeleteMessage: (message: Message) => void;
  onResolveMessage: (id: string) => void;
  onUnresolveMessage: (id: string) => void;
}

interface MessagePanelState {
  message: string;
}

class MessagePanel extends Component<MessagePanelProps, MessagePanelState> {
  constructor(props: MessagePanelProps) {
    super(props);
    this.state = {
      message: ''
    };
  }

  private async onResolveMessage(id: string) {
    this.props.onResolveMessage(id);
  }

  private async onUnresolveMessage(id: string) {
    this.props.onUnresolveMessage(id);
  }

  private async onDeleteMessage(message: Message) {
    this.props.onDeleteMessage(message);
  }

  render() {
    const messages = this.props.messages
      ?
      this.props.messages.map(m => (
        <article key={uuidv4()} className="uk-comment uk-margin-small-bottom uk-box-shadow-small">
          <header className="uk-comment-header uk-grid-medium uk-flex-middle" uk-grid="">
            <div className="uk-width-auto">
              {
                !!m.created && !!!m.resolved
                &&
                <a
                  style={{margin: '0 8px'}}
                  href="#"
                  className="uk-icon-button"
                  uk-icon="check"
                  onClick={ () => this.onResolveMessage(m.id) }
                >
                </a>
              }
              {
                !!m.created && !!m.resolved
                &&
                <a
                  style={{margin: '0 8px', backgroundColor: 'green', color: 'white'}}
                  href="#"
                  className="uk-icon-button"
                  uk-icon="check"
                  onClick={ () => this.onUnresolveMessage(m.id) }
                >
                </a>
              }
              <span uk-icon="icon: user; ratio: 1.5"></span>
            </div>
            <div className="uk-width-expand">
              <h4 className="uk-margin-remove">{m.user}</h4>
              <ul className="uk-comment-meta uk-subnav uk-subnav-divider uk-margin-remove-top">
                <li>
                {
                  m.created
                  ? dayjs(m.created).format('YYYY/MM/DD HH:mm')
                  : 'Not submitted'
                }
                </li>
              </ul>
              {
                m.resolved
                &&
                `Resolved by ${m.resolvedBy} at ${dayjs(m.resolved).format('YYYY/MM/DD HH:mm')}`
              }
            </div>
            {
              !!!m.resolved
              &&
              <div>
                <a
                  href="#"
                  className="uk-icon-button"
                  uk-icon="trash"
                  onClick={ async () => await this.onDeleteMessage(m) }
                >
                </a>
              </div>
            }
          </header>
          <div className="uk-comment-body" style={{ paddingBottom: '8px' }}>
          　<span uk-icon="icon: comment" style={{ marginRight: '8px' }}></span>
            <span>{m.content}</span>
          </div>
        </article>
      ))
      : null;
    return (
      <Fragment>
        {messages}
        <textarea
          className="uk-textarea"
          value={this.state.message}
          onChange={ e => this.setState({ message: e.target.value })}
        >
        </textarea>
        <button
          className="uk-button uk-button-small uk-button-primary"
          disabled={!this.state.message}
          onClick={ e => {
            e.preventDefault();
            this.props.onSaveMessage(this.state.message);
            this.setState({ message: '' });
          }}
        >
          Add message
        </button>
      </Fragment>
    )
  }
}

export default MessagePanel;

