import React, { Component, Fragment } from 'react';

import { Verb } from '../models/Verb';

interface StagePickerProps {
  disabled: boolean;
  verb: Verb;
  onChange: (stage: number) => void;
}
interface VerbEditorStates {
  stage: number;
}

class StagePicker extends Component<StagePickerProps, VerbEditorStates> {
  constructor(props: StagePickerProps) {
    super(props);
    this.state = {
      stage: props.verb.stage
    };
  }

  render() {
    const verb = this.props.verb;
    const options = [];
    let maxStage = verb.stage + 1;
    if (maxStage > 10) {
      maxStage = 10;
    }
    for (let i = 1; i <= maxStage; i++) {
      options.push(<option key={i} value={i}>{i}</option>);
    }
    return (
      <select
        className="uk-select uk-form-width-small"
        value={this.state.stage}
        disabled={this.props.disabled}
        onChange={ e => {
          this.setState({ stage: +e.target.value },
            () => this.props.onChange(this.state.stage)
          );
        }}
      >
        {options}
      </select>
    )
  }
}

export default StagePicker;
