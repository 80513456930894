import './OtherInfoPanel.css';

import { OtherInfo, OtherInfoKey } from '../models/Verb';
import React, { useEffect, useState } from 'react';

interface OtherInfoPanelProps {
  value: number;
  onChange: (value: number) => void;
}

export function OtherInfoPanel(props: OtherInfoPanelProps) {
  const [value, setValue] = useState(props.value);
  useEffect(() => {
    props.onChange(value);
  }, [value]);

  function getChecked(key: OtherInfoKey, value: number): boolean {
    switch (key) {
      case 'idiom':
        return !!(value & OtherInfo.Idiom);
      case 'light-verb':
        return !!(value & OtherInfo.LightVerb);
      case 'adverbial':
        return !!(value & OtherInfo.Adverbial);
      case 'modal':
        return !!(value & OtherInfo.Modal);
      case 'adnominal':
        return !!(value & OtherInfo.Adnominal);
      default:
        throw new Error(`Invalid other info key: ${key}`);
    }
  }

  function handleChange(key: OtherInfoKey, checked: boolean) {
    switch (key) {
      case 'idiom':
        setValue((value) =>
          checked ? value | OtherInfo.Idiom : value & ~OtherInfo.Idiom
        );
        break;
      case 'light-verb':
        setValue((value) =>
          checked ? value | OtherInfo.LightVerb : value & ~OtherInfo.LightVerb
        );
        break;
      case 'adverbial':
        setValue((value) =>
          checked ? value | OtherInfo.Adverbial : value & ~OtherInfo.Adverbial
        );
        break;
      case 'modal':
        setValue((value) =>
          checked ? value | OtherInfo.Modal : value & ~OtherInfo.Modal
        );
        break;
      case 'adnominal':
        setValue((value) =>
          checked ? value | OtherInfo.Adnominal : value & ~OtherInfo.Adnominal
        );
        break;
      default:
        throw new Error(`Invalid other info key: ${key}`);
    }
  }

  return (
    <div className="uk-margin">
      <div>
        <label>その他の情報 (Other information)</label>
      </div>
      <div className="form-group OtherInfoPanel-container">
        <div className="uk-inline">
          <label>
            <input
              className="uk-checkbox"
              type="checkbox"
              checked={getChecked('idiom', value)}
              onChange={(e) => handleChange('idiom', e.target.checked)}
            />
            &nbsp;イディオム (Idiom)
          </label>
        </div>
        <div className="uk-inline">
          <label>
            <input
              className="uk-checkbox"
              type="checkbox"
              checked={getChecked('light-verb', value)}
              onChange={(e) => handleChange('light-verb', e.target.checked)}
            />
            &nbsp;軽動詞的用法 (Light verb)
          </label>
        </div>
        <div className="uk-inline">
          <label>
            <input
              className="uk-checkbox"
              type="checkbox"
              checked={getChecked('adverbial', value)}
              onChange={(e) => handleChange('adverbial', e.target.checked)}
            />
            &nbsp;副詞的用法 (Adverbial)
          </label>
        </div>
        <div className="uk-inline">
          <label>
            <input
              className="uk-checkbox"
              type="checkbox"
              checked={getChecked('adnominal', value)}
              onChange={(e) => handleChange('adnominal', e.target.checked)}
            />
            &nbsp;連体詞的用法 (Adnominal)
          </label>
        </div>
        <div className="uk-inline">
          <label>
            <input
              className="uk-checkbox"
              type="checkbox"
              checked={getChecked('modal', value)}
              onChange={(e) => handleChange('modal', e.target.checked)}
            />
            &nbsp;モダリティ (Modal)
          </label>
        </div>
      </div>
    </div>
  );
}
