import React, { useState } from "react";

export interface VerbDropdownOption<T> {
  label: string;
  value: T;
}

interface VerbDropdownProps<T> {
  options: VerbDropdownOption<T>[];
  selected: T | "";
  width: number;
  onChange: (value: T) => void;
}

export function VerbDropdown<T>(props: VerbDropdownProps<T>) {
  const [selected, setSelected] = useState<T | "">(props.selected);

  const options = [
    <option value="">Select...</option>,
    ...props.options.map((option) => (
      <option
        key={option.value as unknown as string}
        value={option.value as unknown as string}
      >
        {option.label}
      </option>
    )),
  ];

  return (
    <select
      className="uk-select"
      style={{ width: `${props.width}px` }}
      value={selected as unknown as string}
      onChange={(e) => {
        setSelected(e.target.value as unknown as T);
        props.onChange(e.target.value as unknown as T);
      }}
    >
      {options}
    </select>
  );
}
