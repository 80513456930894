import "../styles/Login.css";

import React, { Component, Fragment } from "react";

import axios from "axios";

interface LoginProps {
  onLogin: (data: any) => void;
}

interface LoginState {
  authenticating: boolean;
  username: string;
  password: string;
}

class Login extends Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);
    this.state = {
      authenticating: false,
      username: "",
      password: "",
    };
  }

  async login() {
    const url = "https://auth.dondoushidon.org/api/authenticate";
    //const url = 'http://localhost:8999/api/authenticate';
    const body = `username=${this.state.username}&password=${
      this.state.password
    }`;

    const { data } = await axios.post(url, body, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });

    this.props.onLogin(data);
  }

  render() {
    return (
      <div className="container" id="app">
        <form className="login-form" onSubmit={async (e) => await this.login()}>
          <h3 className="welcome">Welcome to Don動詞どん</h3>
          <p className="error" v-if="formError">
            {}
          </p>
          <label className="uk-form-label">
            Username:&nbsp;
            <input
              type="text"
              className="uk-input"
              value={this.state.username}
              name="username"
              onChange={(e) => {
                this.setState({ username: e.target.value });
              }}
            />
          </label>
          <label className="uk-form-label">
            Password:&nbsp;
            <input
              type="password"
              className="uk-input"
              value={this.state.password}
              name="password"
              onChange={(e) => {
                this.setState({ password: e.target.value });
              }}
            />
          </label>
          <button
            type="button"
            className="uk-button uk-button-primary"
            disabled={this.state.authenticating}
            onClick={async (e) => await this.login()}
          >
            Login
          </button>
        </form>
      </div>
    );
  }
}

export default Login;
