import React, { Component } from 'react';

import CandidatePanel from './CandidatePanel';
import { CandidateType } from '../models/CandidateType';
import { Candidates } from '../models/Candidates';
import Comment from '../models/Comment';
import DataService from '../services/data.service';
import { Example } from '../models/Example';
import ExampleMap from './ExampleMap';
import ExampleMapEdit from './ExampleMapEdit';
import { FullSentenceDefinition } from '../models/FullSentenceDefinition';
import { Message } from '../models/Message';
import MessagePanel from './MessagePanel';
import { StageGroups } from '../models/StageGroups';
import { Suggestion } from '../models/Suggestion';
import TurnPicker from './TurnPicker';
import { User } from '../models/User';
import { Verb } from '../models/Verb';
import _ from 'lodash';

interface SummaryProps {
  username: string;
  verb: Verb;
  jpMembers: User[];
  jpManagers: User[];
  enMembers: User[];
  enManagers: User[];
  stageGroups: StageGroups;
  refresh: boolean;
  messages: Message[];
  onSaveMessage: (content: string) => void;
  onDeleteMessage: (message: Message) => void;
  onResolveMessage: (id: string) => void;
  onUnresolveMessage: (id: string) => void;
  onRefresh: () => void;
  onFsdChange: () => void; // for triggering getting final FSD
  onSaveSummary: (verb: Verb) => Promise<void>;
}

interface SummaryState {
  turnId: string;
  s5AssistantId: string;
  s8AssistantId: string;
  s9AssistantId: string;
  suggestions: Suggestion;
  jpExamples: Example[]; // needs to be shared ones in Candidates
  enExamples: Example[]; // needs to be shared ones in Candidates
  noteForReferenceBook: string; // editable at stage 4, 5 and 8
  fullSentenceDefinition: string; // editable in stage 4, 5, and 8
  fsd: FullSentenceDefinition;
  saving: boolean;
}

class Summary extends Component<SummaryProps, SummaryState> {
  private get FsdEditable() {
    return (
      this.props.verb.stage === 4 ||
      this.props.verb.stage === 5 ||
      this.props.verb.stage === 8
    );
  }

  constructor(props: SummaryProps) {
    super(props);
    this.state = {
      turnId: props.verb.turnId,
      s5AssistantId: props.verb.s5AssistantId,
      s8AssistantId: props.verb.s8AssistantId,
      s9AssistantId: props.verb.s9AssistantId,
      jpExamples: [],
      enExamples: [],
      suggestions: {
        translations: ['', '', '', ''],
        fullSentenceDefinition: '',
        noteForReferenceBook: ''
      } as Suggestion,
      noteForReferenceBook: this.props.verb.noteForReferenceBook || '',
      fullSentenceDefinition: props.verb.fullSentenceDefinition,
      fsd: {} as FullSentenceDefinition,
      saving: false
    };
  }

  async update() {
    const { id, stage } = this.props.verb;
    const fsd =
      (await DataService.getSelectedFsd(id, stage)) ||
      ({
        value: '',
        localId: 0,
        status: 0,
        verbId: id,
        stage,
        selected: true
      } as FullSentenceDefinition);
    this.setState({
      fsd,
      fullSentenceDefinition: fsd.value
    });
    const jpExamples = await DataService.getSelectedExamples(id, stage, 1);
    const enExamples = await DataService.getSelectedExamples(id, stage, 2);
    const suggestions = (await DataService.getSuggestions(id)) || {
      translations: ['', '', '', ''],
      fullSentenceDefinition: '',
      noteForReferenceBook: ''
    };

    // if (!enExamples || enExamples.length === 0) {
    //   const newEnExamples = [] as Example[];
    //   for (let i = 0; i < 4; i++) {
    //     newEnExamples.push({
    //       value: '',
    //       verbId: this.props.verb.id,
    //       selected: true,
    //       localId: i,
    //       language: 2,
    //       stage: this.props.verb.stage,
    //       status: 0
    //     } as Example);
    //   }
    //   enExamples = newEnExamples;
    // }
    this.setState({ jpExamples, enExamples, suggestions });
    this.props.onRefresh();
  }

  async componentDidMount() {
    this.update();
  }

  async componentDidUpdate(prevProps: SummaryProps, prevState: SummaryState) {
    if (prevProps.refresh === false && this.props.refresh === true) {
      this.update();
    }
  }

  onJpExampleChange = (value: string, index: number) => {
    const jpExamples = JSON.parse(JSON.stringify(this.state.jpExamples));
    jpExamples[index].value = value;
    this.setState({ jpExamples });
  };

  onEnExampleChange = (value: string, index: number) => {
    const enExamples = JSON.parse(JSON.stringify(this.state.enExamples));
    enExamples[index].value = value;
    this.setState({ enExamples });
  };

  onTranslationSuggestionChange = (value: string, index: number) => {
    const suggestions = JSON.parse(
      JSON.stringify(this.state.suggestions)
    ) as Suggestion;
    suggestions.translations[index] = value;
    this.setState({ suggestions: suggestions });
  };

  onFsdSuggestionChange = (value: string) => {
    const suggestions = JSON.parse(
      JSON.stringify(this.state.suggestions)
    ) as Suggestion;
    suggestions.fullSentenceDefinition = value;
    this.setState({ suggestions: suggestions });
  };

  onNoteForReferenceBookSuggestionChange = (value: string) => {
    const suggestions = JSON.parse(
      JSON.stringify(this.state.suggestions)
    ) as Suggestion;
    suggestions.noteForReferenceBook = value;
    this.setState({ suggestions: suggestions });
  };

  onNoteForReferenceBookChange = (value: string) => {
    this.setState({ noteForReferenceBook: value });
  };

  onFsdChange = (value: string) => {
    this.setState({ fullSentenceDefinition: value });
  };

  handleTurnChange = (turnId: string) => {
    this.setState({ turnId });
  };

  handleS5AssistantChange = (s5AssistantId: string) => {
    this.setState({ s5AssistantId });
  };

  handleS8AssistantChange = (s8AssistantId: string) => {
    this.setState({ s8AssistantId });
  };

  handleS9AssistantChange = (s9AssistantId: string) => {
    this.setState({ s9AssistantId });
  };

  onSaveSummary = async () => {
    const verb = this.props.verb;
    verb.turnId = this.state.turnId;
    verb.s5AssistantId = this.state.s5AssistantId;
    verb.s9AssistantId = this.state.s9AssistantId;
    verb.messages = this.props.messages;
    verb.noteForReferenceBook = this.state.noteForReferenceBook;
    verb.fullSentenceDefinition = this.state.fullSentenceDefinition;
    await this.props.onSaveSummary(verb);

    if (this.FsdEditable) {
      const fsd = _.cloneDeep(this.state.fsd);
      fsd.value = this.state.fullSentenceDefinition;
      const newFsd = await DataService.updateFullSentenceDefinition(fsd);
      this.setState({ fsd: newFsd });
      this.props.onFsdChange();
    }

    if (verb.stage !== 3 && verb.stage !== 7) {
      const jpExamples = [] as Example[];
      const enExamples = [] as Example[];
      for (let i = 0; i < 4; i++) {
        jpExamples.push(
          _.cloneDeep(await DataService.updateExample(this.state.jpExamples[i]))
        );
        enExamples.push(
          _.cloneDeep(await DataService.updateExample(this.state.enExamples[i]))
        );
      }
      this.setState({
        jpExamples,
        enExamples
      });
    }
    if (verb.stage === 8) {
      const suggestions = _.cloneDeep(
        await DataService.updateSuggestions(verb.id, this.state.suggestions)
      );
      this.setState({
        suggestions
      });
    }
  };

  render() {
    const verb = this.props.verb;
    return (
      <div>
        {verb.stage !== 10 ? (
          <>
            <div className="uk-margin">
              <button
                className="uk-button uk-button-primary"
                disabled={this.state.saving}
                onClick={async (e) => {
                  e.preventDefault();
                  this.setState({ saving: true }, async () => {
                    await this.onSaveSummary();
                    this.setState({ saving: false });
                  });
                }}
              >
                Save Summary
              </button>
            </div>
            <div className="uk-margin">
              <label className="uk-form-label">担当 (Turn)</label>
              <TurnPicker
                disabled={false}
                verb={this.props.verb}
                jpMembers={this.props.jpMembers}
                jpManagers={this.props.jpManagers}
                enMembers={this.props.enMembers}
                enManagers={this.props.enManagers}
                stageGroups={this.props.stageGroups}
                onTurnChange={this.handleTurnChange}
                onS5AssistantChange={this.handleS5AssistantChange}
                onS8AssistantChange={this.handleS8AssistantChange}
                onS9AssistantChange={this.handleS9AssistantChange}
              />
            </div>
          </>
        ) : null}
        <div className="uk-margin">
          <label className="uk-form-label">文型 (Structure)</label>
          <div className="uk-form-controls">{verb.structure}&nbsp;</div>
        </div>
        {this.props.verb.stage < 10 ? (
          <>
            <div className="uk-margin">
              <label className="uk-form-label">
                典型例文 (Representative examples)
              </label>
              <div className="uk-form-controls">
                {verb.representativeExample}&nbsp;
              </div>
            </div>
            <div className="uk-margin">
              <label className="uk-form-label">
                提示文・定義作成に関する注意事項 (For the preparation of the
                definition and examples)
              </label>
              <div className="uk-form-controls">
                {verb.noteForExampleAuthor}&nbsp;
              </div>
            </div>
            <div className="uk-margin">
              <label className="uk-form-label">備考用のメモ (Memo)</label>
              <div className="uk-form-controls">{verb.generalNotes}&nbsp;</div>
            </div>
          </>
        ) : (
          <div className="uk-margin">
            <label className="uk-form-label">
              見出し用例文 (Index examples)
            </label>
            <div className="uk-form-controls">{verb.indexExample}&nbsp;</div>
          </div>
        )}
        <div className="uk-margin">
          <label className="uk-form-label">定義 (Definition)</label>
          {this.FsdEditable ? (
            <textarea
              className="uk-textarea"
              value={this.state.fullSentenceDefinition}
              placeholder="Definition"
              onChange={(e) => this.onFsdChange(e.target.value)}
            ></textarea>
          ) : (
            <div className="uk-form-controls">
              {verb.fullSentenceDefinition}&nbsp;
            </div>
          )}
          {this.props.verb.stage === 8 && (
            <textarea
              className="uk-textarea"
              value={this.state.suggestions.fullSentenceDefinition}
              placeholder="Suggestion"
              onChange={(e) => this.onFsdSuggestionChange(e.target.value)}
            ></textarea>
          )}
        </div>
        <hr />
        {this.props.verb.stage >= 9 ? (
          <ExampleMap verb={this.props.verb} />
        ) : (
          <ExampleMapEdit
            verb={this.props.verb}
            jpExamples={this.state.jpExamples.map((e) => e.value)}
            enExamples={this.state.enExamples.map((e) => e.value)}
            suggestions={this.state.suggestions.translations}
            onJpExampleChange={this.onJpExampleChange}
            onEnExampleChange={this.onEnExampleChange}
            onTranslationSuggestionChange={this.onTranslationSuggestionChange}
          />
        )}
        <hr />
        <div className="uk-margin">
          <label className="uk-form-label">備考 (Note)</label>
          {this.props.verb.stage === 4 ||
          this.props.verb.stage === 5 ||
          this.props.verb.stage === 8 ? (
            <textarea
              className="uk-textarea"
              value={this.state.noteForReferenceBook}
              onChange={(e) =>
                this.onNoteForReferenceBookChange(e.target.value)
              }
            ></textarea>
          ) : (
            <div className="uk-form-controls">
              {verb.noteForReferenceBook}&nbsp;
            </div>
          )}
          {this.props.verb.stage === 8 && (
            <textarea
              className="uk-textarea"
              value={this.state.suggestions.noteForReferenceBook}
              placeholder="Suggestion"
              onChange={(e) =>
                this.onNoteForReferenceBookSuggestionChange(e.target.value)
              }
            ></textarea>
          )}
        </div>
        {verb.stage !== 10 ? (
          <div className="uk-margin">
            <label className="uk-form-label">
              他のStage担当者へのメッセージ (Messages for people responsible for
              other stages)
            </label>
            <MessagePanel
              messages={this.props.messages || []}
              onSaveMessage={this.props.onSaveMessage}
              onResolveMessage={this.props.onResolveMessage}
              onUnresolveMessage={this.props.onUnresolveMessage}
              onDeleteMessage={this.props.onDeleteMessage}
            ></MessagePanel>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Summary;
