import '../styles/ControlPanel.css';

import React, { ChangeEvent, Component, Fragment } from 'react';
import axios, { CancelTokenSource } from 'axios';

import Config from '../config';
import { DisplayMode } from '../models/DisplayMode';
import UploadDialog from './UploadDialog';
import { User } from '../models/User';
import dataService from '../services/data.service';
import dayjs from 'dayjs';
import downloadService from '../services/download.service';
import uuidv4 from 'uuid/v4';

const UIkit = require('uikit');

interface ControlPanelProps {
  token: string;
  onModeChange: (mode: DisplayMode) => void;
  onSearch: (value: string) => void;
  onFilterByStage: (stage: number) => void;
  onFilterByUser: (userId: string) => void;
  onDataUpload: () => void;
}

interface ControlPanelState {
  displayMode: string;
  searchWord: string;
  users: User[];
  downloading: boolean;
  fullDownload: boolean;
}

class ControlPanel extends Component<ControlPanelProps, ControlPanelState> {
  private exportChecker: number = 0;

  constructor(props: ControlPanelProps) {
    super(props);
    this.state = {
      displayMode: 'all',
      searchWord: '',
      users: [],
      downloading: false,
      fullDownload: true
    };
  }

  componentDidMount() {
    this.loadAllUsers();
  }

  loadAllUsers() {
    const token = localStorage.getItem('d3');
    axios.get(`${Config.apiUrl}/api/users?token=${token}`).then((res) => {
      const users = res.data as User[];
      const hiddenUsers = users.filter((u) => u.displayName.startsWith('雇用'));
      const visibleUsers = users.filter(
        (u) => !u.displayName.startsWith('雇用')
      );
      const usersCombined = [...visibleUsers, ...hiddenUsers];
      this.setState({ users: usersCombined });
    });
  }

  onModeChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.props.onModeChange(e.target.value as DisplayMode);
    this.setState({ displayMode: e.target.value });
  };

  onFilterByStage = (e: ChangeEvent<HTMLSelectElement>) => {
    this.props.onFilterByStage(+e.target.value);
  };

  onFilterByUser = (e: ChangeEvent<HTMLSelectElement>) => {
    this.props.onFilterByUser(e.target.value);
  };

  download = async (
    full: boolean = true,
    cb: (error: any) => void
  ): Promise<CancelTokenSource> => {
    const getFileName = (full: boolean) => {
      const today = dayjs().format('YYYYMMDD');
      const fullSuffix = full ? '-full' : '';
      return `ddd-export${fullSuffix}-${today}.xlsx`;
    };

    const cancelToken = axios.CancelToken;
    const cancelTokenSource = cancelToken.source();
    axios({
      method: 'GET',
      headers: {
        authorization: `${this.props.token}`
      },
      responseType: 'blob', // important
      url: `${Config.dlUrl}/api/download?full=${this.state.fullDownload}`,
      onDownloadProgress: (progressEvent: any) => {
        console.log(progressEvent);
      },
      cancelToken: cancelTokenSource.token
    })
      .then((response) => {
        this.setState({ downloading: false });
        cb(null);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', getFileName(this.state.fullDownload));
        document.body.appendChild(link);
        link.click();
      })
      .catch((err: any) => {
        if (axios.isCancel(err)) {
          cb(null);
        } else {
          cb(err);
        }
      });

    return cancelTokenSource;
  };

  exportExcel = async () => {
    this.setState({ downloading: true });
    this.download(this.state.fullDownload, (error) => console.error(error));
  };

  render() {
    const options = this.state.users.map((option) => (
      <option value={option.userId} key={option.userId}>
        {option.displayName}
      </option>
    ));
    const userTurns = [
      <option value="" key="0">
        Off
      </option>,
      ...options
    ];

    return (
      <Fragment>
        <div className="control-panel">
          <div>
            <div className="uk-inline uk-margin uk-grid-small uk-child-width-auto uk-grid">
              <label className="uk-form-label">Mode:&nbsp;</label>
              <label>
                <input
                  className="uk-radio"
                  type="radio"
                  name="display-mode"
                  value="all"
                  checked={this.state.displayMode === 'all'}
                  onChange={this.onModeChange}
                />
                &nbsp;Normal
              </label>
              <label>
                <input
                  className="uk-radio"
                  type="radio"
                  name="display-mode"
                  value="stage2"
                  checked={this.state.displayMode === 'stage2'}
                  onChange={this.onModeChange}
                />
                &nbsp;Stage 2
              </label>
              <label>
                <input
                  className="uk-radio"
                  type="radio"
                  name="display-mode"
                  value="stage6"
                  checked={this.state.displayMode === 'stage6'}
                  onChange={this.onModeChange}
                />
                &nbsp;Stage 6
              </label>
              <span className="download-buttons">
                <button
                  className="uk-button uk-button-default"
                  disabled={this.state.downloading}
                  onClick={this.exportExcel}
                >
                  {this.state.downloading ? 'Downloading...' : 'Download'}
                </button>
                &nbsp;
                <label>
                  <input
                    className="uk-checkbox"
                    type="checkbox"
                    checked={this.state.fullDownload}
                    onChange={(e) =>
                      this.setState({ fullDownload: e.target.checked })
                    }
                  />
                  &nbsp;Full
                </label>
                {this.state.downloading && (
                  <span uk-spinner="" style={{ marginLeft: '10px' }}></span>
                )}
                <a
                  href="#"
                  className="uk-icon-button"
                  style={{ marginLeft: '30px' }}
                  uk-icon="upload"
                  onClick={(e) => {
                    e.preventDefault();
                    UIkit.modal('#upload-dialog').show();
                  }}
                ></a>
              </span>
            </div>
          </div>
          <div className="uk-inline">
            <form className="uk-search uk-search-default">
              {this.state.displayMode === 'all' && (
                <a
                  href="#"
                  uk-search-icon=""
                  onClick={(e) => this.props.onSearch(this.state.searchWord)}
                ></a>
              )}
              <input
                className="uk-search-input uk-search-disabled"
                type="search"
                placeholder={
                  this.state.displayMode === 'all' ? 'Search...' : ''
                }
                disabled={this.state.displayMode !== 'all'}
                value={this.state.searchWord}
                onChange={(e) => this.setState({ searchWord: e.target.value })}
              />
              {this.state.displayMode === 'all' && (
                <a
                  href="#"
                  className="uk-form-icon uk-form-icon-flip"
                  uk-icon="icon: close"
                  onClick={(e) => {
                    this.setState({ searchWord: '' }, () =>
                      this.props.onSearch(this.state.searchWord)
                    );
                  }}
                ></a>
              )}
            </form>
            <label className="uk-form-label uk-margin-left uk-margin-right">
              Filter by:&nbsp;
            </label>
            <div className="uk-inline">
              <label className="uk-form-label">Stage:&nbsp;</label>
              <select
                className="uk-select"
                style={{ width: '90px' }}
                disabled={this.state.displayMode !== 'all'}
                onChange={this.onFilterByStage}
              >
                <option value="0">Off</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
              </select>
              <label className="uk-form-label uk-margin-left">
                Turn:&nbsp;
              </label>
              <select
                className="uk-select"
                style={{ width: '180px' }}
                disabled={this.state.displayMode !== 'all'}
                onChange={this.onFilterByUser}
              >
                {userTurns}
              </select>
            </div>
          </div>
        </div>
        <div>
          <UploadDialog
            onClose={() => {
              UIkit.modal('#upload-dialog').hide();
            }}
            onDataUpload={this.props.onDataUpload}
          />
        </div>
      </Fragment>
    );
  }
}

export default ControlPanel;
