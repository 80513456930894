import { Message } from './Message';
import { Text } from './Text';
import { VerbDropdownOption } from '../components/VerbDropdown';

export type Conjugation =
  | 'Godan'
  | 'Ichidan'
  | 'Sahen'
  | 'Kahen'
  | 'I-adjective'
  | 'NA';
export type Origin = 'J' | 'SJ1' | 'SJ2' | 'E';
export type Makeup =
  | 'V'
  | 'VV'
  | 'VteV'
  | 'NV'
  | 'AV'
  | 'Rare'
  | 'Re'
  | 'Sase'
  | 'Sas'
  | 'Neg'
  | 'Idiom'
  | 'Adv'
  | 'Other';

export type Uses = 'Neutral' | 'Sonkei' | 'Kenjo' | 'Benefactive';

export const ConjugationOptions: VerbDropdownOption<Conjugation>[] = [
  {
    label: 'Godan',
    value: 'Godan'
  },
  { label: 'Ichidan', value: 'Ichidan' },
  { label: 'Sahen', value: 'Sahen' },
  { label: 'I-adjective', value: 'I-adjective' },
  { label: '---', value: 'NA' }
];

export const OriginOptions: VerbDropdownOption<Origin>[] = [
  { label: 'Native Japanese', value: 'J' },
  { label: 'Sino-Japanese 1', value: 'SJ1' },
  { label: 'Sino-Japanese 2', value: 'SJ2' },
  { label: 'Loanword', value: 'E' }
];

export const MakeupOptions: VerbDropdownOption<Makeup>[] = [
  { label: 'Simple', value: 'V' },
  { label: 'VV compound', value: 'VV' },
  { label: 'VteV compound', value: 'VteV' },
  { label: 'NV compound', value: 'NV' },
  { label: 'AV compound', value: 'AV' },
  { label: 'Compound with rare', value: 'Rare' },
  { label: 'Compound with re', value: 'Re' },
  { label: 'Compound with sase', value: 'Sase' },
  { label: 'Compound with sas', value: 'Sas' },
  { label: 'Compound with negation', value: 'Neg' },
  { label: 'Other compounds', value: 'Other' }
];

export const UsesOptions: VerbDropdownOption<Uses>[] = [
  { label: 'Neutral', value: 'Neutral' },
  { label: 'Sonkei', value: 'Sonkei' },
  { label: 'Kenjo', value: 'Kenjo' },
  { label: 'Benefactive', value: 'Benefactive' }
];

export enum OtherInfo {
  Idiom = 0x0001,
  LightVerb = 0x0002,
  Adverbial = 0x0004,
  Modal = 0x0008,
  Adnominal = 0x0010
}

export type OtherInfoKey =
  | 'idiom'
  | 'light-verb'
  | 'adverbial'
  | 'modal'
  | 'adnominal';

export interface Verb extends Text {
  localIndex: number;
  groupId: string;
  structure: string;
  englishIndex: string;
  fullSentenceDefinition: string;
  noteForReferenceBook: string;
  representativeExample: string;
  noteForExampleAuthor: string;
  generalNotes: string;
  jpAssigneeId: string;
  enAssigneeId: string;
  refAssigneeId: string;
  jpCheckerId: string;
  enCheckerId: string;
  refCheckerId: string;
  assignees: string[];
  turnId: string;
  messages: Message[];
  selected: boolean;
  links1String: string;
  links1: string[]; // Verb id list
  links2String: string;
  links2: string[]; // Verb id list
  links3String: string;
  links3: string[]; // Verb id list
  s5AssistantId: string;
  s8AssistantId: string;
  s9AssistantId: string;
  stem: string;
  conjugation: Conjugation;
  origin: Origin;
  makeup: Makeup;
  uses: Uses;
  indexExample: string;
  otherInfo: number; //* Idiom = 0x0001, LightVerb = 0x0002, Adverbial = 0x0004, Modal = 0x0008
}
