import React, { Fragment, useState, useEffect } from 'react';

import '../styles/Header.css';
import HistoryDialog from './HistoryDialog';
import Config from '../config';

const UIkit = require('uikit');

interface HeaderProps {
  username: string;
  onLogout: () => void;
}

const Header = (props: HeaderProps) => {
  const [showHistory, setShowHistory] = useState(false);

  useEffect(() => {
    if (showHistory) {
      UIkit.modal(
        '#history-dialog',
        {bgclose: false, modal: false}
      ).show();
    }
  }, [showHistory])

  const showHistoryDialog = () => {
    setShowHistory(true);
  }

  const hideHistoryDialog = () => {
    UIkit.modal(
      '#history-dialog'
    ).hide();
    setShowHistory(false);
  }

  return (
    <Fragment>
    <div className="header-bar">
      <div className="header-title">Don 動詞どん</div>
      {
        Config.env !== 'prod'
        &&
        <span>&nbsp;{Config.env}</span>
      }
        <div className="logout">
          <button
            onClick={props.onLogout}
            className="uk-button uk-button-primary">
            Logout
          </button>
      </div>
      <div className="user header-icon">
        <span uk-icon="icon: user"></span>
          &nbsp;{props.username}
      </div>
      <div
        className="history header-icon"
        onClick={ () => showHistoryDialog() }
      >
        <span uk-icon="icon: history"></span>
      </div>
    </div>
    {
    showHistory
    &&
    <div>
      <HistoryDialog
        onClose={ () => hideHistoryDialog() }
      />
    </div>
    }
    </Fragment>
  )
}

export default Header;
