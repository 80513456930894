import dayjs from 'dayjs';
import React, { Component, Fragment } from 'react';
import { CandidateType } from '../models/CandidateType';
import { Verb } from '../models/Verb';
import { Candidates } from '../models/Candidates';
import { FullSentenceDefinition } from '../models/FullSentenceDefinition';
import TextOption from '../models/TextOption';
import Comment from '../models/Comment';
import dataService from '../services/data.service';
import { Example } from '../models/Example';

interface ExampleMapProps {
  verb: Verb;
  refresh?: boolean; // used for refreshing English translations when their best choice changes in stage 7
  onRefresh?: () => void;
}

interface ExampleMapState {
  examples: string[][];
}

class ExampleMap extends Component<ExampleMapProps, ExampleMapState> {
  constructor(props: ExampleMapProps) {
    super(props);
    this.state = {
      examples: []
    };
  }

  async componentDidMount() {
    this.update();
  }

  async update() {
    const { id, stage } = this.props.verb;
    const jpExamples = await dataService.getSelectedExamples(id, stage, 1);
    const enExamples = await dataService.getSelectedExamples(id, stage, 2);
    const examples = [];
    for (let i = 0; i < 4; i++) {
      const jp = jpExamples.find(e => e.localId === i);
      const en = enExamples.find(e => e.localId === i);
      const pair = [] as string[];
      pair.push(
        jp ? jp.value : '',
        en ? en.value : ''
      );
      examples.push(pair);
    }
    this.setState({
      examples
    });
    if (this.props.onRefresh) {
      this.props.onRefresh();
    }
  }

  async componentDidUpdate(prevProps: ExampleMapProps, prevState: ExampleMapState) {
    if (prevProps.refresh === false && this.props.refresh === true) {
      this.update();
    }
  }

  render() {
    return (
      <table className="uk-table uk-table-striped">
        <tbody>
        {
          this.state.examples.map((pair, index) =>
          <tr key={index}>
              <td><span className="uk-label">{`#${index+1}`}</span></td>
              <td className="col-sm-6">
                <span dangerouslySetInnerHTML={{__html: pair[0] || 'No example selected'}}></span>
              </td>
              <td className="col-sm-6">
                <span dangerouslySetInnerHTML={{__html: pair[1] || 'No example selected'}}></span>
              </td>
          </tr>)
        }
        </tbody>
      </table>
    );
  }
}

export default ExampleMap;
