import React, {
  Component,
  useEffect,
  useImperativeMethods,
  useState
} from 'react';

import { User } from '../models/User';
import UserHistory from '../models/UserHistory';

interface HistoryTableProps {
  users: User[];
  history: UserHistory[];
}

interface HistoryEntryProps {
  name: string;
}

const HistoryTable = (props: HistoryTableProps) => {
  const NoEntries = (props: HistoryEntryProps) => {
    const items = [<td key={props.name}>{props.name}</td>];
    for (let i = 0; i < 9; i++) {
      items.push(<td key={`${props.name}-${i}`}>0</td>);
    }

    return <tr key={props.name}>{items}</tr>;
  };
  const hiddenUsers = props.users.filter((u) =>
    u.displayName.startsWith('雇用')
  );
  const visibleUsers = props.users.filter(
    (u) => !u.displayName.startsWith('雇用')
  );
  const users = [...visibleUsers, ...hiddenUsers];
  return (
    <table className="uk-table">
      <thead>
        <tr>
          <th></th>
          <th>Stage 1</th>
          <th>Stage 3</th>
          <th>Stage 3</th>
          <th>Stage 4</th>
          <th>Stage 5</th>
          <th>Stage 7</th>
          <th>Stage 7</th>
          <th>Stage 8</th>
          <th>Stage 9</th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th>(作成)</th>
          <th>(確認)</th>
          <th></th>
          <th></th>
          <th>(作成)</th>
          <th>(確認)</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => {
          const history = props.history.find((h) => h.userId === user.userId);
          return history === undefined ? (
            <NoEntries key={user.userId} name={user.displayName} />
          ) : (
            <tr key={user.userId}>
              <td>{user.displayName}</td>
              <td>{history.numCompleted[0]}</td>
              <td>{history.stage3Completed[0]}</td>
              <td>{history.stage3Completed[1]}</td>
              <td>{history.numCompleted[3]}</td>
              <td>{history.numCompleted[4]}</td>
              <td>{history.stage7Completed[0]}</td>
              <td>{history.stage7Completed[1]}</td>
              <td>{history.numCompleted[7]}</td>
              <td>{history.numCompleted[8]}</td>
            </tr>
          );
        })}
        <tr>
          <td>企画、構成、計画、編集</td>
          <td colSpan={9}>りんげ、田中、上山</td>
        </tr>
        <tr>
          <td>データベース運営</td>
          <td colSpan={9}>尾関</td>
        </tr>
      </tbody>
    </table>
  );
};

export default HistoryTable;
