import React, { Component } from 'react';

import CandidatePanel from './CandidatePanel';
import { CandidateType } from '../models/CandidateType';
import { Candidates } from '../models/Candidates';
import Comment from '../models/Comment';
import { Example } from '../models/Example';
import ExampleMap from './ExampleMap';
import { FullSentenceDefinition } from '../models/FullSentenceDefinition';
import { Verb } from '../models/Verb';
import dataService from '../services/data.service';

interface FullSentenceDefinitionPanelProps {
  username: string;
  verb: Verb;
  refresh: boolean;
  onRefresh: () => void;
  onBestChoiseUpdate: (value: string) => void;
}

class FullSentenceDefinitionPanel extends Component<FullSentenceDefinitionPanelProps> {
  constructor(props: FullSentenceDefinitionPanelProps) {
    super(props);
  }

  render() {
    const verb = this.props.verb;
    return (
      <div>
        <div className="uk-form-horizontal">
          <div className="uk-margin">
            <label className="uk-form-label">文型 (Structure)</label>
            <div className="uk-form-controls">{verb.structure}&nbsp;</div>
          </div>
          <div className="uk-margin">
            <label className="uk-form-label">定義 (Definition)</label>
            <div className="uk-form-controls">
              {verb.fullSentenceDefinition}&nbsp;
            </div>
          </div>
        </div>
        <ExampleMap
          verb={this.props.verb}
          refresh={this.props.refresh || false}
          onRefresh={this.props.onRefresh}
        />
        <hr />
        <CandidatePanel<FullSentenceDefinition>
          username={this.props.username}
          verb={this.props.verb}
          type="fsd"
          localId={0}
          language={0}
          onBestChoiseUpdate={this.props.onBestChoiseUpdate}
        />
      </div>
    );
  }
}

export default FullSentenceDefinitionPanel;
