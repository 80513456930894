import React, { useState, useEffect } from 'react';
import TextOption from '../models/TextOption';
import { CandidateType } from '../models/CandidateType';

interface CandidateEditProps<T> {
  candidate: T;
  type: CandidateType;
  localId: number; // Example: 0 - 3, FSD: 0
  selected: boolean;
  onSaveBestchoice: (candidate: T) => void;
  onSaveCandidate: (candidate: T) => void;
  onDeleteCandidate: (candidate: T) => void;
}

function CandidateEdit<T extends TextOption>(props: CandidateEditProps<T>) {
  const [candidateValue, setCandidateValue]
    = useState(props.candidate ? props.candidate.value : '');
  const [selected, setSelected] = useState(props.selected);

  useEffect(() => {
    console.log(`Local id: ${props.localId} Set selected with ${props.selected}`)
    setSelected(props.selected);
  }, [props.selected]);

  const textareaClass = props.candidate.selected
  ? 'uk-textarea uk-form-success'
  : 'uk-textarea';

  return (
    <div className="uk-margin-small">
      <textarea
        className={textareaClass}
        style={{width: '80%'}}
        value={candidateValue}
        onChange={ e => setCandidateValue(e.target.value) }
      >
      </textarea>
      <a
        href="#" className="uk-icon-button uk-margin-left uk-margin-right"
        uk-icon="icon: check"
        onClick={ e => {
          const candidate = { ...props.candidate };
          candidate.value = candidateValue;
          props.onSaveCandidate(candidate);
        } }
      >
      </a>
      <a
        href="#"
        className="uk-icon-button uk-margin-right"
        uk-icon="icon: trash"
        onClick={ () => props.onDeleteCandidate(props.candidate) }
      >
      </a>
      <input
        type="radio"
        className="uk-radio"
        name={`${props.type}-bestchoice-${props.localId}`}
        // defaultChecked={props.candidate ? props.candidate.selected : false}
        value={props.candidate.localId}
        disabled={!!!props.candidate.created}
        checked={props.selected}
        onChange={ e => {
          console.debug('onChange' + e.target.checked)
          setSelected(e.target.checked );
          const candidate = { ...props.candidate };
          candidate.selected = true;
          props.onSaveBestchoice(candidate);
        }}
      />
    </div>
    );
  }

export default CandidateEdit;
