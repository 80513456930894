import React, { useState } from 'react';

import { FinalCheckList } from '../models/FinalCheckList';

interface ChecklistProps {
  checklist: FinalCheckList;
  onKanjiChange: (value: boolean) => void;
  onPartialOrderChange: (value: boolean) => void;
  onLinksChange: (value: boolean) => void;
  onLexicalInfoChange: (value: boolean) => void;
  onEnglishIndexChange: (valud: boolean) => void;
  onIndexExamplesChange: (value: boolean) => void;
  onChange: (value: FinalCheckList) => void;
}

const Checklist = (props: ChecklistProps) => {
  return (
    <div className="uk-margin">
      <div className="form-group">
        <label className="col-sm-3">
          <input
            className="uk-checkbox"
            type="checkbox"
            value="kanji"
            checked={props.checklist.kanji}
            onChange={(e) => props.onKanjiChange(e.target.checked)}
          />
          &nbsp;漢字
        </label>
      </div>
      <div className="form-group">
        <label className="col-sm-3">
          <input
            className="uk-checkbox"
            type="checkbox"
            checked={props.checklist.partialOrder}
            onChange={(e) => props.onPartialOrderChange(e.target.checked)}
          />
          &nbsp;表示順位
        </label>
      </div>
      <div className="form-group">
        <label className="col-sm-3">
          <input
            className="uk-checkbox"
            type="checkbox"
            checked={props.checklist.links}
            onChange={(e) => props.onLinksChange(e.target.checked)}
          />
          &nbsp;対応関係
        </label>
      </div>
      <div className="form-group">
        <label className="col-sm-3">
          <input
            className="uk-checkbox"
            type="checkbox"
            checked={props.checklist.lexicalInfo}
            onChange={(e) => props.onLexicalInfoChange(e.target.checked)}
          />
          &nbsp;語彙情報
        </label>
      </div>
      <div className="form-group">
        <label className="col-sm-3">
          <input
            className="uk-checkbox"
            type="checkbox"
            checked={props.checklist.englishIndex}
            onChange={(e) => props.onEnglishIndexChange(e.target.checked)}
          />
          &nbsp;英語インデックス
        </label>
      </div>
      <div className="form-group">
        <label className="col-sm-3">
          <input
            className="uk-checkbox"
            type="checkbox"
            checked={props.checklist.indexExamples}
            onChange={(e) => props.onIndexExamplesChange(e.target.checked)}
          />
          &nbsp;見出し用例文
        </label>
      </div>
    </div>
  );
};

export default Checklist;
