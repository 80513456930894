const Config = {
  dev: {
    apiUrl: "http://localhost:5000",
    dlUrl: "http://localhost:4000",
    env: "dev",
  },
  staging: {
    apiUrl: "https://test.api.dondoushidon.org",
    dlUrl: "https://test.api2.dondoushidon.org",
    env: "staging",
  },
  prod: {
    apiUrl: "https://api.dondoushidon.org",
    dlUrl: "https://api2.dondoushidon.org",
    env: "prod",
  },
};

let _config = Config.dev;
switch (process.env.REACT_APP_STAGE) {
  case "staging":
    _config = Config.staging;
    break;
  case "prod":
    _config = Config.prod;
    break;
}

export default _config;
