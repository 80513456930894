import dayjs from 'dayjs';
import React, { Component, Fragment } from 'react';
import { CandidateType } from '../models/CandidateType';
import { Verb } from '../models/Verb';
import { Candidates } from '../models/Candidates';
import { FullSentenceDefinition } from '../models/FullSentenceDefinition';
import TextOption from '../models/TextOption';
import Comment from '../models/Comment';

interface CommentEditProps {
  comment: Comment;
  onSaveComment: (comment: Comment) => void;
}

interface CommentEditState {
  commentValue: string;
}

class CommentEdit
  extends Component<CommentEditProps, CommentEditState> {
  constructor(props: CommentEditProps) {
    super(props);
    this.state = {
      commentValue: props.comment.value
    };
  }

  componentDidMount() {
    this.setState({
      commentValue: this.props.comment.value
    });
  }

  render() {
    const comment = this.props.comment;
    return (
      <div className="uk-margin-small">
        <div style={{display: 'inline-block', width: '80%'}}>
          <div style={{display: 'inline-block', width: '20%'}}>
            <span uk-icon="user"></span>
            <span className="uk-margin-small-left">{comment.author}</span>
            <div style={{fontSize: '14px'}}>
            {
              comment.created
              ? dayjs(comment.created).format('YYYY/MM/DD HH:mm')
              : 'Not submitted'
            }
            </div>
          </div>
          <textarea
            className="uk-textarea"
            style={{width: '80%'}}
            defaultValue={this.props.comment.value}
            value={this.state.commentValue}
            onChange={ e => this.setState({ commentValue: e.target.value })}>
          </textarea>
        </div>
        <a
          href="#"
          className="uk-icon-button uk-margin-left uk-margin-right"
          uk-icon="icon: check"
          onClick={ e => {
            const comment = { ...this.props.comment } as Comment;
            comment.value = this.state.commentValue;
            this.props.onSaveComment(comment);
          }}
        />
        <a
          href="#"
          className="uk-icon-button uk-margin-right"
          uk-icon="icon: trash"
          onClick={ e => {
            const comment = { ...this.props.comment } as Comment;
            comment.status = 1;
            this.props.onSaveComment(comment);
          }}
        />
      </div>
    );
  }
}

export default CommentEdit;
