import React, { Component } from 'react';

import CandidatePanel from './CandidatePanel';
import { CandidateType } from '../models/CandidateType';
import { Candidates } from '../models/Candidates';
import Comment from '../models/Comment';
import { Example } from '../models/Example';
import { Verb } from '../models/Verb';
import dataService from '../services/data.service';

interface EnExamplePanelProps {
  username: string;
  verb: Verb;
  localId: number;
  onBestChoiseUpdate: (value: string) => void;
}

interface EnExamplePanelState {
  jpExample: string;
}

class EnExamplePanel extends Component<
  EnExamplePanelProps,
  EnExamplePanelState
> {
  constructor(props: EnExamplePanelProps) {
    super(props);
    this.state = {
      jpExample: ''
    };
  }

  async componentDidMount() {
    const verb = this.props.verb;
    const candidates = await dataService.getCandidates<Example>(
      'jp',
      verb.id,
      verb.stage,
      1,
      this.props.localId
    );
    const selected = candidates.filter((c) => c.selected);
    const jpExample = selected && selected[0] ? selected[0].value : '';
    this.setState({ jpExample });
  }

  render() {
    const verb = this.props.verb;
    return (
      <div>
        <div className="uk-form-horizontal">
          <div className="uk-margin">
            <label className="uk-form-label">文型 (Structure)</label>
            <div className="uk-form-controls">{verb.structure}&nbsp;</div>
          </div>
          <div className="uk-margin">
            <label className="uk-form-label">定義 (Definition)</label>
            <div className="uk-form-controls">
              {verb.fullSentenceDefinition}&nbsp;
            </div>
          </div>
          <div className="uk-margin">
            <label className="uk-form-label">提示文 (Sentence)</label>
            <div
              className="uk-form-controls"
              dangerouslySetInnerHTML={{ __html: this.state.jpExample }}
            />
          </div>
        </div>
        <hr />
        <CandidatePanel<Example>
          username={this.props.username}
          verb={this.props.verb}
          type="en"
          localId={this.props.localId}
          language={2}
          onBestChoiseUpdate={this.props.onBestChoiseUpdate}
        />
      </div>
    );
  }
}

export default EnExamplePanel;
