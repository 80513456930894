import dayjs from 'dayjs';
import React, { Fragment, useState } from 'react';
import Switch from 'react-switch';
import Header from './Header';
import VerbTable from './VerbTable';
import ControlPanel from './ControlPanel';
import { DisplayMode } from '../models/DisplayMode';
import ProgressPanel from './ProgressPanel';

interface VerbAppProps {
  token: string;
  username: string;
  onLogout: () => void;
}

const VerbApp = (props: VerbAppProps) => {
  const [displayMode, setDisplayMode] = useState<DisplayMode>('all');
  const [searchWord, setSearchWord] = useState('');
  const [filterStage, setFilterStage] = useState(0);
  const [filterUserId, setFilterUserId] = useState('');
  const [dataUploaded, setDataUploaded] = useState(false);
  const [showProgress, setShowProgress] = useState(true);
  const [lastUpdate, setLastupdate] = useState(dayjs().unix());

  const onModeChange = (mode: DisplayMode) =>
    setDisplayMode(mode);

  const onSearch = (value: string) =>
    setSearchWord(value);

  const onFilterByStage = (stage: number) =>
    setFilterStage(stage);

  const onFilterByUser = (userId: string) =>
    setFilterUserId(userId);

  const onDataUpload = () =>
    setDataUploaded(true);

  const onDataRefresh = () => {
    setDataUploaded(false);
    setLastupdate(dayjs().unix());
  }

  const onProgressSwitch = (on: boolean) =>
    setShowProgress(on);

  return (
    <Fragment>
      <Header
        username={props.username}
        onLogout={props.onLogout}
      />
      <div style={{ padding: '8px' }}>
        <label>
          <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
          <Switch
            uncheckedIcon={false}
            checkedIcon={false}
            onChange={onProgressSwitch}
            checked={showProgress}
          />
          </div>
          <div style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '8px' }}>Progress</div>
        </label>
      </div>
      {
        showProgress
        &&
        <Fragment>
          <ProgressPanel
            lastUpdate={lastUpdate}
          />
          <hr />
        </Fragment>
      }
      <ControlPanel
        token={props.token}
        onModeChange={onModeChange}
        onSearch={onSearch}
        onFilterByStage={onFilterByStage}
        onFilterByUser={onFilterByUser}
        onDataUpload={onDataUpload}
      />
      <VerbTable
        username={props.username}
        displayMode={displayMode}
        searchWord={searchWord}
        filterStage={filterStage}
        filterUserId={filterUserId}
        dataUploaded={dataUploaded}
        onDataRefresh={onDataRefresh}
      />
    </Fragment>
  );
}

export default VerbApp;
