import React, { Component } from 'react';

import CandidatePanel from './CandidatePanel';
import { CandidateType } from '../models/CandidateType';
import { Candidates } from '../models/Candidates';
import Comment from '../models/Comment';
import { Example } from '../models/Example';
import { Verb } from '../models/Verb';
import dataService from '../services/data.service';

interface JpExamplePanelProps {
  username: string;
  verb: Verb;
  localId: number;
  onBestChoiseUpdate: (value: string) => void;
}

class JpExamplePanel extends Component<JpExamplePanelProps> {
  constructor(props: JpExamplePanelProps) {
    super(props);
  }

  render() {
    const verb = this.props.verb;
    return (
      <div>
        <div className="uk-margin">
          <label className="uk-form-label">文型 (Structure)</label>
          <div className="uk-form-controls">{verb.structure}&nbsp;</div>
        </div>
        <div className="uk-margin">
          <label className="uk-form-label">
            典型例文 (Representative examples)
          </label>
          <div className="uk-form-controls">
            {verb.representativeExample}&nbsp;
          </div>
        </div>
        <div className="uk-margin">
          <label className="uk-form-label">
            提示文・定義作成に関する注意事項 (For the preparation of the
            definition and examples)
          </label>
          <div className="uk-form-controls">
            {verb.noteForExampleAuthor}&nbsp;
          </div>
        </div>
        <div className="uk-margin">
          <label className="uk-form-label">備考用のメモ (Memo)</label>
          <div className="uk-form-controls">{verb.generalNotes}&nbsp;</div>
        </div>
        <div className="uk-margin">
          <label className="uk-form-label">定義 (Definition)</label>
          <div className="uk-form-controls">
            {verb.fullSentenceDefinition}&nbsp;
          </div>
        </div>
        <hr />
        <CandidatePanel<Example>
          username={this.props.username}
          verb={this.props.verb}
          type="jp"
          localId={this.props.localId}
          language={1}
          onBestChoiseUpdate={this.props.onBestChoiseUpdate}
        />
      </div>
    );
  }
}

export default JpExamplePanel;
