import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import VerbEditor from './VerbEditor';
import { Verb } from '../models/Verb';
import { User } from '../models/User';
import dataService from '../services/data.service';
import { StageGroups } from '../models/StageGroups';
import Config from '../config';
import { Example } from '../models/Example';

interface DuplicateVerbDialogProps {
  verb: Verb;
  onClose: () => void;
  onSave: (verb: Verb, jpExamples: Example[], enExamples: Example[]) => void;
}

interface DuplicateVerbDialogState {
  verb: string;
  jpExamples: Example[];
  enExamples: Example[];
  jpCopyFlags: boolean[];
  enCopyFlags: boolean[];
}

class DuplicateVerbDialog
  extends Component<DuplicateVerbDialogProps, DuplicateVerbDialogState> {
  private  nameInput: HTMLInputElement | null = null;

  constructor(props: DuplicateVerbDialogProps) {
    super(props);
    this.state = {
      verb: props.verb.value,
      jpExamples: [],
      enExamples: [],
      jpCopyFlags: [ false, false, false, false ],
      enCopyFlags: [ false, false, false, false ]
    };
  }

  async componentDidMount() {
    if (this.nameInput) {
      this.nameInput.setAttribute('autofocus', 'true');
    }
    const { id, stage } = this.props.verb;
    let jpExamples = await dataService.getSelecteExamples(1, id, stage);
    if (_.isEmpty(jpExamples)) {
      jpExamples = [{ value: '' }, { value: '' }, { value: '' }, { value: '' }] as Example[];
    }
    let enExamples = await dataService.getSelecteExamples(2, id, stage);
    if (_.isEmpty(jpExamples)) {
      enExamples = [{ value: '' }, { value: '' }, { value: '' }, { value: '' }] as Example[];
    }
    this.setState({ jpExamples, enExamples });
  }

  private anyJpExamples() {
    return this.state.jpExamples.filter(jpExample => jpExample.value).length > 0;
  }

  render() {
    return (
    <div id="duplicate-verb-dialog" className="uk-modal" uk-modal='{ "bg-close": false }'>
      <div className="uk-modal-dialog uk-width-1-9">
        <button
          className="uk-modal-close-default"
          type="button"
          uk-close=""
          onClick={ () => this.props.onClose() }
        >
        </button>
        <div className="uk-modal-header">
            <div className="uk-modal-title">
              Duplicate verb
            </div>
        </div>
        <div className="uk-modal-body">
          <table>
          {
            this.anyJpExamples() &&
            <tr><th>提示文</th><th>英訳</th></tr>
          }
          {
            this.state.jpExamples.map((jpExample, index) => {
              const enExample = !_.isEmpty(this.state.enExamples)
                ? this.state.enExamples[index]
                : { id: `en-${index}` } as Example;
              return jpExample.value
              ?
              <tr>
                <td>
                  <input
                    type="checkbox"
                    className="uk-checkbox"
                    id={jpExample.id}
                    checked={this.state.jpCopyFlags[index]}
                    disabled={!jpExample.value}
                    onChange={
                      e => {
                        const flags = this.state.jpCopyFlags;
                        flags[index] = e.target.checked;
                        this.setState({ jpCopyFlags: flags });
                      }
                    }
                  />
                  &nbsp;
                  <label
                    className="uk-form-label"
                    htmlFor={jpExample.id}
                    dangerouslySetInnerHTML={{__html: jpExample.value}}
                  >
                  </label>
                </td>
                <td>
                  <input
                    type="checkbox"
                    className="uk-checkbox"
                    id={enExample.id}
                    checked={this.state.enCopyFlags[index]}
                    disabled={!enExample.value}
                    onChange={
                      e => {
                        const flags = this.state.enCopyFlags;
                        flags[index] = e.target.checked;
                        this.setState({ enCopyFlags: flags });
                      }
                    }
                  />
                  &nbsp;
                  <label
                    className="uk-form-label"
                    htmlFor={enExample.id}
                    dangerouslySetInnerHTML={{__html: enExample.value || 'N/A'}}
                  >
                  </label>
                </td>
              </tr>
              :
              null
            })
          }
          </table>
          <label className="uk-form-label">New Verb</label>
          <input
            type="text"
            className="uk-input"
            value={this.state.verb}
            name="username"
            ref={ input => this.nameInput = input }
            onChange={ e => {
              this.setState({ verb: e.target.value });
            }}
          />
        </div>
        <div className="uk-modal-footer uk-text-right">
          <button
            className="uk-button"
            type="button"
            onClick={ () => this.props.onClose() }
          >
            Cancel
          </button>
          <button
            className="uk-button uk-button-primary"
            type="button"
            disabled={ this.state.verb.trim() === '' || this.state.verb.trim() === this.props.verb.value }
            onClick={this.onSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
    );
  }

  private onSave = () => {
    console.log('save ' + this.state.verb);
    const copy = JSON.parse(JSON.stringify(this.props.verb)) as Verb;
    copy.value = this.state.verb;
    const copiedJpExamples = this.state.jpExamples.map((e, index) => {
      if (!this.state.jpCopyFlags[index]) { e.value = '' }
      return e;
    });
    const copiedEnExamples = this.state.enExamples.map((e, index) => {
      if (!this.state.enCopyFlags[index]) { e.value = '' }
      return e;
    });
    this.props.onSave(copy, copiedJpExamples, copiedEnExamples);
    this.props.onClose();
  }
}

export default DuplicateVerbDialog;
