import React, { Component } from 'react';
import VerbEditor from './VerbEditor';
import { Verb } from '../models/Verb';
import { User } from '../models/User';
import dataService from '../services/data.service';
import { StageGroups } from '../models/StageGroups';

interface VerbEditDialogProps {
  username: string;
  selectedVerb: Verb;
  jpMembers: User[];
  jpManagers: User[];
  enMembers: User[];
  enManagers: User[];
  stageGroups: StageGroups;
  onClose: () => void;
  onSaveSettings: (verb: Verb) => Promise<void>;
  onChangeStage: (stage: number) => void;
  onFsdChange: (value: string) => void;
}

class VerbEditDialog extends Component<VerbEditDialogProps> {
  render() {
    return (
    <div id="editor" className="uk-modal" uk-modal='{ "bg-close": false }'>
      <div className="uk-modal-dialog uk-width-3-4">
        <button
          className="uk-modal-close-default"
          type="button"
          uk-close=""
          onClick={this.props.onClose}
        >
        </button>
        <div className="uk-modal-header">
            <div className="uk-modal-title">
              {this.props.selectedVerb.value}
            <span className="uk-text-small uk-margin-left">
              Stage
            </span>&nbsp;
            <span className="uk-badge">
              {this.props.selectedVerb.stage}
            </span>
            </div>
        </div>
        <div className="uk-modal-body">
          <VerbEditor
            username={this.props.username}
            verb={this.props.selectedVerb}
            jpMembers={this.props.jpMembers}
            jpManagers={this.props.jpManagers}
            enMembers={this.props.enMembers}
            enManagers={this.props.enManagers}
            stageGroups={this.props.stageGroups}
            onSaveSettings={this.props.onSaveSettings}
            onChangeStage={this.props.onChangeStage}
            onFsdChange={this.props.onFsdChange}
          />
        </div>
        <div className="uk-modal-footer uk-text-right">
          <button
            className="uk-modal-close-full uk-close-large"
            type="button"
            uk-close=""
            onClick={this.props.onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
    );
  }
}

export default VerbEditDialog;
