import axios from 'axios';
import React, { Component, ChangeEvent } from 'react';
import VerbEditor from './VerbEditor';
import { Verb } from '../models/Verb';
import { User } from '../models/User';
import dataService from '../services/data.service';
import { StageGroups } from '../models/StageGroups';
import Config from '../config';

interface UploadDialogProps {
  onClose: () => void;
  onDataUpload: () => void;
}

interface UploadDialogState {
  uploadFormData: FormData | null;
  combineFormData: FormData | null;
}

class UploadDialog
  extends Component<UploadDialogProps, UploadDialogState> {
  constructor(props: UploadDialogProps) {
    super(props);
    this.state = {
      uploadFormData: null,
      combineFormData: null
    }
  }

  render() {
    return (
    <div id="upload-dialog" className="uk-modal" uk-modal='{ "bg-close": false }'>
      <div className="uk-modal-dialog uk-width-1-4">
        <button
          className="uk-modal-close-default"
          type="button"
          uk-close=""
          onClick={ () => this.props.onClose() }
        >
        </button>
        <div className="uk-modal-header">
            <div className="uk-modal-title">
              Upload Excel file
            </div>
        </div>
        <div className="uk-modal-body">
          <div className="uk-margin">
          <label className="uk-form-label">Upload an Excel file:</label>
          <input className="uk-input" type="file" name="files" id="files" onChange={this.onFileSelected} multiple />
          </div>
          <div className="form-group">
            <button
              className="uk-button"
              disabled={this.state.uploadFormData === null}
              onClick={this.uploadExcelFile}
            >
              Upload
            </button>
          </div>
        <hr />
        <form>
          <div className="uk-margin">
            <label className="uk-form-label">Example sentence file:</label>
            <input
              className="uk-input"
              type="file"
              name="datafiles"
              id="exampleSentenceFile"
              onChange={this.onExampleFileSelected} multiple 
            />
          </div>
          <div className="uk-margin">
            <label className="uk-form-label">Main data file:</label>
            <input
              className="uk-input"
              type="file"
              name="datafiles"
              id="mainDataFile"
              onChange={this.onMainFileSelected} multiple 
            />
          </div>
          <div className="uk-margin">
            <button
              className="uk-button"
              disabled={this.state.combineFormData === null}
              onClick={async (e) => {
                e.preventDefault();
                await this.combineExcelFiles();
              } }
            >
              Combine Data Files
            </button>
          </div>
        </form>

        </div>
        <div className="uk-modal-footer uk-text-right">
          <button
            className="uk-button"
            type="button"
            onClick={ () => this.props.onClose() }
          >
            Close
          </button>
        </div>
      </div>
    </div>
    );
  }

  private onFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files)
      const formData = new FormData();
      files.forEach(f => {
        formData!.append('files', f);
      });
      this.setState({
        uploadFormData: formData
      });
    }
  }

  private onExampleFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files)
      const formData = new FormData();
      files.forEach(f => {
        formData!.append('files', f);
      });
      this.setState({
        combineFormData: formData
      });
    }
  }

  private onMainFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files)
      const formData = this.state.combineFormData;
      files.forEach(f => {
        formData!.append('files', f);
      });
      this.setState({
        combineFormData: formData
      });
    }
  }

  private uploadExcelFile = async () => {
    const token = localStorage.getItem('d3');

    const { data } = await axios.post(`${Config.apiUrl}/api/UploadFile?token=${token}`,
     this.state.uploadFormData,
     { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    this.props.onDataUpload();
    this.props.onClose();
  }

  private combineExcelFiles = async () => {
    // Make Ajax request with the contentType = false, and procesDate = false
    const { data } = await axios.post(
      `${Config.apiUrl}/api/CombineFiles`,
      this.state.combineFormData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    window.open(`${Config.apiUrl}/api/GetDataFile?filePath=${data}`, '_blank');
  }
}

export default UploadDialog;
