import React from 'react';

import '../styles/Overlay.css';

interface OverlayProps {
  spinner: boolean;
}

const Overlay = (props: OverlayProps) => {
  return (
    <div className="overlay">
      {
        props.spinner
        &&
        <div className="spinner" uk-spinner="ratio: 5"></div>
      }
    </div>
  )
}

export default Overlay;
