import React, { useEffect, useState } from 'react';

import DataService from '../services/data.service';
import UserProgress from '../models/UserProgress';

interface ProgressPanelProps {
  lastUpdate: number;
}

const ProgressPanel = (props: ProgressPanelProps) => {
  const [stageProgress, setStageProgress] = useState<number[]>([]);
  const [userProgress, setUserProgress] = useState<UserProgress[]>([]);

  useEffect(() => {
    DataService.getStageProgress().then((value) => {
      setStageProgress(value);
    });
    DataService.getUserProgress().then((value) => {
      setUserProgress(value);
    });
  }, [props.lastUpdate]);

  const stageHeader = [] as JSX.Element[];
  const stageBody = [] as JSX.Element[];
  for (let i = 0; i < 10; i++) {
    stageHeader.push(
      <th key={`stage-header-${i}`} style={{ textAlign: 'center' }}>{`Stage${
        i + 1
      }`}</th>
    );
    stageBody.push(
      <td key={`stage-body-${i}`} style={{ textAlign: 'center' }}>
        {stageProgress[i]}
      </td>
    );
  }

  const userHeader = [] as JSX.Element[];
  const userBody = [] as JSX.Element[];
  const visibleUsers = userProgress.filter((u) => !u.name.startsWith('雇用'));
  const hiddenUsers = userProgress.filter((u) => u.name.startsWith('雇用'));
  for (let up of [...visibleUsers, ...hiddenUsers]) {
    userHeader.push(
      <th
        key={`user-header-${up.name}`}
        style={{ textAlign: 'center' }}
      >{`${up.name}`}</th>
    );
    userBody.push(
      <td key={`user-header-${up.name}`} style={{ textAlign: 'center' }}>
        {up.numAssigned}
      </td>
    );
  }
  return (
    <div>
      <table className="uk-table uk-table-small  uk-table-divider">
        <thead>
          <tr>{stageHeader}</tr>
        </thead>
        <tbody>
          <tr>{stageBody}</tr>
        </tbody>
      </table>
      <table className="uk-table uk-table-small  uk-table-divider">
        <thead>
          <tr>{userHeader}</tr>
        </thead>
        <tbody>
          <tr>{userBody}</tr>
        </tbody>
      </table>
    </div>
  );
};

export default ProgressPanel;
