import React, { Component, Fragment } from 'react';

import CandidateEdit from './CandidateEdit';
import { CandidateType } from '../models/CandidateType';
import { Candidates } from '../models/Candidates';
import Comment from '../models/Comment';
import CommentEdit from './CommentEdit';
import { Example } from '../models/Example';
import { FullSentenceDefinition } from '../models/FullSentenceDefinition';
import { Language } from '../models/Language';
import TextOption from '../models/TextOption';
import { Verb } from '../models/Verb';
import _ from 'lodash';
import dataService from '../services/data.service';

interface CandidatePanelProps<T> {
  username: string;
  verb: Verb;
  type: CandidateType;
  localId: number;
  language: Language;
  onBestChoiseUpdate: (value: string) => void;
}

interface CandidatePanelState<T> {
  candidates: Candidates<T>;
  comments: Comment[];
}

class CandidatePanel<T extends TextOption> extends Component<
  CandidatePanelProps<T>,
  CandidatePanelState<T>
> {
  constructor(props: CandidatePanelProps<T>) {
    super(props);
    this.state = {
      comments: [],
      candidates: {} as Candidates<T>
    };
  }

  get LastCandidate(): T | null {
    const numCandidates = this.state.candidates.items
      ? this.state.candidates.items.length
      : 0;
    return numCandidates
      ? this.state.candidates.items[numCandidates - 1]
      : null;
  }

  async componentDidMount() {
    const verb = this.props.verb;
    const candidates = await dataService.getCandidates<T>(
      this.props.type,
      verb.id,
      verb.stage,
      this.props.language,
      this.props.localId
    );

    this.setState({
      candidates: {
        items: candidates,
        selectedIndex: candidates
          ? candidates.findIndex((ex) => ex.selected)
          : -1
      }
    });

    await this.loadComments();
  }

  async loadComments() {
    this.setState(
      {
        comments: []
      },
      async () => {
        const comments = [] as Comment[];
        const candidates = this.state.candidates.items;
        for (let i = candidates.length - 1; i >= 0; i--) {
          const c = candidates[i];
          const comms = await dataService.getComments(c.id);
          comments.push(...comms);
        }
        this.setState({
          comments
        });
      }
    );
  }

  onAddCandidate = () => {
    const candidates = { ...this.state.candidates };
    candidates.items.push(this.createCandidate());
    this.setState({ candidates });
  };

  onAddComment = (linkId: string) => {
    const comments = [...this.state.comments];
    comments.push({
      value: '',
      author: this.props.username,
      linkId
    } as Comment);
    this.setState({
      comments
    });
  };

  onSaveComment = async (comment: Comment) => {
    await dataService.saveComment(comment);
    await this.loadComments();
  };

  onSaveBestchoice = async (selected: T) => {
    const candidates = [...this.state.candidates.items];
    let selectedIndex = 0;
    for (let i = 0; i < candidates.length; i++) {
      const candidate = candidates[i];
      candidate.selected = candidate.id === selected.id;
      if (candidate.selected) {
        selectedIndex = i;
      }
      await this.onSaveCandidate(candidate);
    }
    this.setState({
      candidates: {
        items: candidates,
        selectedIndex
      }
    });
  };

  onSaveCandidate = async (candidate: T) => {
    const updated =
      this.props.type === 'fsd'
        ? await dataService.updateFullSentenceDefinition(
            candidate as FullSentenceDefinition
          )
        : await dataService.updateExample(candidate as Example);
    if (this.props.type === 'fsd') {
      this.props.verb.fullSentenceDefinition = candidate.value;
      await dataService.updateVerb(this.props.verb);
    }
    const candidates = [...this.state.candidates.items];
    const i = candidates.findIndex((c) => c.id === candidate.id);
    candidates.splice(i, 1, updated as T);
    this.setState({
      candidates: {
        items: candidates,
        selectedIndex: this.state.candidates.selectedIndex
      }
    });
    if (candidate.selected) {
      this.props.onBestChoiseUpdate(candidate.value);
    }
  };

  onDeleteCandidate = async (candidate: T) => {
    if (candidate.created) {
      candidate.status = 1;
      if (this.props.type === 'fsd') {
        dataService.updateFullSentenceDefinition(candidate);
      } else {
        dataService.updateExample(candidate);
      }
    }
    if (candidate.selected) {
      this.props.onBestChoiseUpdate(candidate.value);
    }
    const copy = _.cloneDeep(this.state.candidates);
    const index = this.state.candidates.items.findIndex(
      (x) => x.id === candidate.id
    );
    copy.items.splice(index, 1);
    this.setState({ candidates: copy });
  };

  createCandidate = () => {
    return {
      value: '',
      stage: this.props.verb.stage,
      localId: this.props.localId,
      language: this.props.language,
      verbId: this.props.verb.id,
      author: this.props.username
    } as T;
  };

  render() {
    return (
      <Fragment>
        {this.state.candidates.items && this.state.candidates.items.length ? (
          this.state.candidates.items.map((item: T, index: number) => {
            const linkedComments = this.state.comments.filter(
              (c) => c.linkId === item.id
            );
            return (
              <Fragment key={item.id}>
                <CandidateEdit<T>
                  candidate={item}
                  type={this.props.type}
                  localId={this.props.localId}
                  selected={item.selected}
                  onSaveBestchoice={this.onSaveBestchoice}
                  onSaveCandidate={this.onSaveCandidate}
                  onDeleteCandidate={this.onDeleteCandidate}
                />
                {linkedComments &&
                  linkedComments.length > 0 &&
                  linkedComments.map((c) => (
                    <CommentEdit
                      comment={c}
                      onSaveComment={this.onSaveComment}
                    />
                  ))}
              </Fragment>
            );
          })
        ) : (
          <CandidateEdit<T>
            candidate={this.createCandidate()}
            type={this.props.type}
            localId={this.props.localId}
            selected={false}
            onSaveBestchoice={this.onSaveBestchoice}
            onSaveCandidate={this.onSaveCandidate}
            onDeleteCandidate={this.onDeleteCandidate}
          />
        )}
        {this.LastCandidate ? (
          <a
            href="#"
            className="uk-icon-button uk-margin-right"
            uk-icon="icon: comment"
            onClick={(e) => this.onAddComment(this.LastCandidate!.id)}
          />
        ) : null}
        <a
          href="#"
          className="uk-icon-button"
          uk-icon="icon: plus"
          onClick={this.onAddCandidate}
        />
      </Fragment>
    );
  }
}

export default CandidatePanel;
