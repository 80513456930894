import React, { Component } from 'react';
import './App.css';

import 'uikit/dist/css/uikit.min.css';

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import Login from './components/Login';
import VerbApp from './components/VerbApp';

const UIkit = require('uikit');
const Icons  = require('uikit/dist/js/uikit-icons');

// loads the Icon plugin
UIkit.use(Icons);

interface AppState {
  token: string | null;
  username: string;
}

class App extends Component<any, AppState> {
  get TokenStorageKey() { return 'd3'; }
  get UserStorageKey() { return 'd3-user'; }

  constructor(props: any) {
    super(props);
    this.state = {
      token: localStorage.getItem(this.TokenStorageKey),
      username: localStorage.getItem(this.UserStorageKey)
        ? JSON.parse(localStorage.getItem(this.UserStorageKey)!).username
        : ''
    };
  }

  onLogin = (data: any) => {
    if (data && data.token) {
      localStorage.setItem(this.TokenStorageKey, data.token);
      localStorage.setItem(this.UserStorageKey, JSON.stringify(data));
      this.setState({
        token: data.token,
        username: data.username
      });
    } else {
      localStorage.removeItem(this.TokenStorageKey);
      localStorage.removeItem(this.UserStorageKey);
      this.setState({
        token: '',
        username: ''
      });
    }
  }

  onLogout = () => {
    localStorage.removeItem(this.TokenStorageKey);
    localStorage.removeItem(this.UserStorageKey);
    this.setState({ token: '' });
  }

  render() {
    return (
      <div className="App">
      {
        this.state.token
        ?
        <VerbApp
          token={this.state.token}
          username={this.state.username}
          onLogout={this.onLogout}
        />
        :
        <Login onLogin={this.onLogin} />
      }
      </div>
    );
  }
}

export default App;
