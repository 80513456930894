import React, { Component, Fragment, useEffect, useState } from 'react';

import DataService from '../services/data.service';
import EnExamplePanel from './EnExamplePanel';
import { FullSentenceDefinition } from '../models/FullSentenceDefinition';
import FullSentenceDefinitionPanel from './FullSentenceDefinitionPanel';
import JpExamplePanel from './JpExamplePanel';
import { Message } from '../models/Message';
import Settings from './Settings';
import { StageGroups } from '../models/StageGroups';
import StagePanel from './StagePanel';
import StagePicker from './StagePicker';
import Summary from './Summary';
import { User } from '../models/User';
import { Verb } from '../models/Verb';
import _ from 'lodash';
import dataService from '../services/data.service';
import uuid from 'uuid';

const UIkit = require('uikit');

interface VerbEditorProps {
  username: string;
  verb: Verb;
  jpMembers: User[];
  jpManagers: User[];
  enMembers: User[];
  enManagers: User[];
  stageGroups: StageGroups;
  onSaveSettings: (verb: Verb) => Promise<void>;
  onChangeStage: (stage: number) => void;
  onFsdChange: (value: string) => void;
}

interface VerbEditorStates {
  verb: Verb;
  finalFsd?: FullSentenceDefinition;
  summaryRefresh: boolean;
  messages: Message[]; // shared between Settings and Summary
}

const VerbEditor = (props: VerbEditorProps) => {
  const [verb, setVerb] = useState(props.verb);
  const [finalFsd, setFinalFsd] = useState<FullSentenceDefinition>(
    {} as FullSentenceDefinition
  );
  const [summaryRefresh, setSummaryRefresh] = useState(false);
  const [definitionTabRefresh, setDefinitionTabRefresh] = useState(false);
  const [messages, setMessages] = useState<Message[]>(
    props.verb.messages || []
  );

  useEffect(() => {
    getFinalFsd();
    getMessages();
  }, []);

  const getFinalFsd = async () => {
    const finalFsd = await dataService.getFinalFullSentenceDefinition(
      props.verb.id,
      props.verb.stage
    );
    setFinalFsd(finalFsd);
  };

  const getMessages = async () => {
    const messages = await dataService.getMessagesAsync(props.verb.id);
    setMessages(messages);
  };

  const onSaveMessage = (content: string) => {
    const message = {
      id: uuid(),
      verbId: props.verb.id,
      content,
      user: props.username
    } as Message;
    const copy = _.cloneDeep(messages);
    copy.push(message);
    setMessages(copy);
  };

  const onResolveMessage = async (id: string) => {
    const messages = await DataService.resolveMessageAsync(id, props.username);
    setMessages(messages);
  };

  const onUnresolveMessage = async (id: string) => {
    const messages = await DataService.unresolveMessageAsync(
      id,
      props.username
    );
    setMessages(messages);
  };

  const onDeleteMessage = async (message: Message) => {
    if (message.created) {
      const messages = await DataService.deleteMessageAsync(message.id);
      setMessages(messages);
    } else {
      const copy = _.cloneDeep(messages);
      const index = copy.findIndex((m) => m.id === message.id);
      if (index >= 0) {
        copy.splice(index, 1);
        setMessages(copy);
      }
    }
  };

  const onSaveSettings = async (verb: Verb) => {
    await props.onSaveSettings(verb);
    await getMessages();
  };

  const onSaveSummary = async (verb: Verb) => {
    const updated = await DataService.updateVerb(verb);
    await getMessages();
  };

  const stage = props.verb.stage;
  const examples = [0, 1, 2, 3];
  return (
    <Fragment>
      <form className="uk-form-stacked">
        <ul uk-tab="">
          <li className="uk-active">
            <a href="#">Settings</a>
          </li>
          {stage === 3 &&
            examples.map((i) => (
              <li key={`jp-${i}`}>
                <a href="#">{`提示文#${i + 1}`}</a>
              </li>
            ))}
          {stage === 7 && (
            <Fragment>
              {examples.map((i) => (
                <li key={`en-${i}`}>
                  <a href="#">{`英訳#${i + 1}`}</a>
                </li>
              ))}
              <li>
                <a href="#">Definition</a>
              </li>
            </Fragment>
          )}
          {stage >= 3 && stage <= 10 && (
            <li>
              <a href="#">Summary</a>
            </li>
          )}
          <li>
            <a href="#">Stage</a>
          </li>
        </ul>
        <ul className="uk-switcher uk-margin">
          <li>
            <Settings
              username={props.username}
              verb={props.verb}
              finalFsd={finalFsd ? finalFsd.value : ''}
              jpMembers={props.jpMembers}
              jpManagers={props.jpManagers}
              enMembers={props.enMembers}
              enManagers={props.enManagers}
              messages={messages}
              onSaveMessage={onSaveMessage}
              onResolveMessage={onResolveMessage}
              onUnresolveMessage={onUnresolveMessage}
              onDeleteMessage={onDeleteMessage}
              onSaveSettings={onSaveSettings}
            />
          </li>
          {stage === 3 &&
            examples.map((i) => (
              <li key={`jp-${i}`}>
                <JpExamplePanel
                  username={props.username}
                  verb={props.verb}
                  localId={i}
                  onBestChoiseUpdate={() => setSummaryRefresh(true)}
                />
              </li>
            ))}
          {stage === 7 && (
            <>
              {examples.map((i) => (
                <li key={`en-${i}`}>
                  <EnExamplePanel
                    username={props.username}
                    verb={props.verb}
                    localId={i}
                    onBestChoiseUpdate={() => {
                      setSummaryRefresh(true);
                      setDefinitionTabRefresh(true);
                    }}
                  />
                </li>
              ))}
              <li>
                <FullSentenceDefinitionPanel
                  username={props.username}
                  verb={props.verb}
                  refresh={definitionTabRefresh}
                  onRefresh={() => setDefinitionTabRefresh(false)}
                  onBestChoiseUpdate={async (value) => {
                    setSummaryRefresh(true);
                    await getFinalFsd();
                    props.onFsdChange(value);
                  }}
                />
              </li>
            </>
          )}
          {stage >= 3 && stage <= 10 && (
            <Summary
              username={props.username}
              verb={props.verb}
              jpMembers={props.jpMembers}
              jpManagers={props.jpManagers}
              enMembers={props.enMembers}
              enManagers={props.enManagers}
              stageGroups={props.stageGroups}
              refresh={summaryRefresh}
              messages={messages}
              onSaveMessage={onSaveMessage}
              onResolveMessage={onResolveMessage}
              onUnresolveMessage={onUnresolveMessage}
              onDeleteMessage={onDeleteMessage}
              onRefresh={() => setSummaryRefresh(false)}
              onFsdChange={async () => {
                await getFinalFsd();
              }}
              onSaveSummary={onSaveSummary}
            />
          )}
          <li>
            <StagePanel verb={props.verb} onChangeStage={props.onChangeStage} />
          </li>
        </ul>
      </form>
    </Fragment>
  );
};

export default VerbEditor;
