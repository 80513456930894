import '../styles/Header.css';

import React, { Component, Fragment } from 'react';

import Checklist from './Checklist';
import DataService from '../services/data.service';
import { FinalCheckList } from '../models/FinalCheckList';
import StagePicker from './StagePicker';
import { Verb } from '../models/Verb';

interface StagePanelProps {
  verb: Verb;
  onChangeStage: (stage: number) => void;
}

interface StagePanelState {
  stage: number;
  checklist: FinalCheckList;
  saving: boolean;
}

class StagePanel extends Component<StagePanelProps, StagePanelState> {
  private get ChecklistComplete() {
    return (
      this.state.checklist.kanji &&
      this.state.checklist.partialOrder &&
      this.state.checklist.links &&
      this.state.checklist.lexicalInfo &&
      this.state.checklist.englishIndex &&
      this.state.checklist.indexExamples
    );
  }

  private get CanSave() {
    const prevStage = this.props.verb.stage;
    return (
      prevStage !== this.state.stage &&
      (prevStage !== 9 ||
        this.state.stage < prevStage ||
        this.ChecklistComplete)
    );
  }

  constructor(props: StagePanelProps) {
    super(props);
    this.state = {
      checklist: {
        kanji: false,
        partialOrder: false,
        links: false,
        lexicalInfo: false,
        englishIndex: false,
        indexExamples: false
      } as FinalCheckList,
      stage: props.verb.stage,
      saving: false
    };
  }

  async componentDidMount() {
    const checklist = await DataService.getChecklist(this.props.verb.id);
    if (checklist) {
      this.setState({ checklist });
    }
  }

  private onKanjiChange = (value: boolean) => {
    const copy = JSON.parse(
      JSON.stringify(this.state.checklist)
    ) as FinalCheckList;
    copy.kanji = value;
    this.setState(
      { checklist: copy },
      async () =>
        await DataService.updateChecklist(
          this.props.verb.id,
          this.state.checklist
        )
    );
  };

  private onPartialOrderChange = (value: boolean) => {
    const copy = JSON.parse(
      JSON.stringify(this.state.checklist)
    ) as FinalCheckList;
    copy.partialOrder = value;
    this.setState(
      { checklist: copy },
      async () =>
        await DataService.updateChecklist(
          this.props.verb.id,
          this.state.checklist
        )
    );
  };

  private onLinksChange = (value: boolean) => {
    const copy = JSON.parse(
      JSON.stringify(this.state.checklist)
    ) as FinalCheckList;
    copy.links = value;
    this.setState(
      { checklist: copy },
      async () =>
        await DataService.updateChecklist(
          this.props.verb.id,
          this.state.checklist
        )
    );
  };

  private onLexicalInfoChange = (value: boolean) => {
    const copy = JSON.parse(
      JSON.stringify(this.state.checklist)
    ) as FinalCheckList;
    copy.lexicalInfo = value;
    this.setState(
      { checklist: copy },
      async () =>
        await DataService.updateChecklist(
          this.props.verb.id,
          this.state.checklist
        )
    );
  };

  private onEnglishIndexChange = (value: boolean) => {
    const copy = JSON.parse(
      JSON.stringify(this.state.checklist)
    ) as FinalCheckList;
    copy.englishIndex = value;
    this.setState(
      { checklist: copy },
      async () =>
        await DataService.updateChecklist(
          this.props.verb.id,
          this.state.checklist
        )
    );
  };

  private onIndexExamplesChange = (value: boolean) => {
    const copy = JSON.parse(
      JSON.stringify(this.state.checklist)
    ) as FinalCheckList;
    copy.indexExamples = value;
    this.setState(
      { checklist: copy },
      async () =>
        await DataService.updateChecklist(
          this.props.verb.id,
          this.state.checklist
        )
    );
  };

  private onChecklistChange = (value: FinalCheckList) => {
    this.setState({ checklist: value });
  };

  private onSaveClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.setState({ saving: true }, async () => {
      this.props.onChangeStage(this.state.stage);
    });
  };

  render() {
    return (
      <Fragment>
        {this.props.verb.stage === 10 && (
          <div className="uk-alert-warning" uk-alert="">
            <span className="label label-default font-size-100">
              This entry has been completed. Changing the stage will reset the
              final checks.
            </span>
          </div>
        )}
        {this.props.verb.stage === 9 && (
          <Checklist
            checklist={this.state.checklist}
            onKanjiChange={this.onKanjiChange}
            onPartialOrderChange={this.onPartialOrderChange}
            onLinksChange={this.onLinksChange}
            onLexicalInfoChange={this.onLexicalInfoChange}
            onEnglishIndexChange={this.onEnglishIndexChange}
            onIndexExamplesChange={this.onIndexExamplesChange}
            onChange={this.onChecklistChange}
          />
        )}
        <label className="uk-form-label">ステージ (Stage)</label>
        <StagePicker
          verb={this.props.verb}
          disabled={false}
          onChange={(stage) => this.setState({ stage })}
        />
        <button
          className="uk-button uk-button-primary"
          disabled={!this.CanSave || this.state.saving}
          onClick={this.onSaveClick}
        >
          Change Stage
        </button>
      </Fragment>
    );
  }
}

export default StagePanel;
