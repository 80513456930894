import axios from 'axios';
import React, { Component } from 'react';
import AsyncSelect from 'react-select/lib/Async';

import VerbEditor from './VerbEditor';
import { Verb } from '../models/Verb';
import { User } from '../models/User';
import Config from '../config';
import { ValueType } from 'react-select/lib/types';
import dataService from '../services/data.service';
import { VerbLink } from '../models/VerbLink';

interface VerbPickerProps {
  targetId: string; // ID of current selected verb
  selectedIds: string[];
  onChange: (newValue: ValueType<VerbOption>) => void;
}

interface VerbPickerState {
  selectedOptions: VerbOption[];
}

export interface VerbOption {
  value: string;
  label: string;
}

class VerbPicker extends Component<VerbPickerProps, VerbPickerState> {
  constructor(props: VerbPickerProps) {
    super(props);
    this.state = {
      selectedOptions: []
    };
  }

  private getLinkLabel = (value: Verb | VerbLink): string =>
    `${value.value} [${value.structure}] ${value.representativeExample}`

  async componentDidMount() {
    await this.getSelectedLinks();
  }

  async componentDidUpdate(prevProps: VerbPickerProps, prevState: VerbPickerState) {
    if (prevProps.selectedIds?.length !== this.props.selectedIds?.length) {
      await this.getSelectedLinks();
    }
  }

  async getSelectedLinks() {
    const selectedIds = this.props.selectedIds || [];
    const links = await Promise.all(selectedIds.map(async id => {
      const link = await dataService.getVerbLink(id);
      return link;
    }));
    this.setState({ selectedOptions: links.map(link => {
      return {
        value: link.id,
        label: this.getLinkLabel(link)
      } })
    });
  }

  private loadOptions = (inputValue: string, callback: (options: VerbOption[]) => void) => {
    axios.get<Verb[]>(`${Config.apiUrl}/api/verbs/${inputValue}?excludeId=${this.props.targetId}`).then((response) => {
      var verbs = response.data;
      const options = verbs.map(v => {
        return {
          value: v.id,
          label: this.getLinkLabel(v)
        }
      });
      callback(options);
    });
  }

  render() {
    console.debug(`render.VerbPicker() ${JSON.stringify(this.state.selectedOptions)}`);
    return (
      <AsyncSelect
        isMulti={true}
        loadOptions={this.loadOptions}
        value={this.state.selectedOptions}
        // defaultOptions={this.state.selectedOptions}
        onChange={
          (newValue: ValueType<VerbOption>) => {
            this.props.onChange(newValue)
          }
        }
      />
    );
  }
}

export default VerbPicker;
