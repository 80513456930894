import React, { ChangeEvent, Component } from 'react';

import DataService from '../services/data.service';
import { StageGroups } from '../models/StageGroups';
import { User } from '../models/User';
import { Verb } from '../models/Verb';

interface TurnPickerProps {
  disabled: boolean;
  verb: Verb;
  jpMembers: User[];
  jpManagers: User[];
  enMembers: User[];
  enManagers: User[];
  stageGroups: StageGroups;
  onTurnChange: (turnId: string) => void;
  onS5AssistantChange: (id: string) => void;
  onS8AssistantChange: (id: string) => void;
  onS9AssistantChange: (id: string) => void;
}

interface TurnPickerStates {
  turnId: string;
  s5AssistantId?: string;
  s8AssistantId?: string;
  s9AssistantId?: string;
  allUsers: User[];
}

class TurnPicker extends Component<TurnPickerProps, TurnPickerStates> {
  // private readonly Ueyama = this.getUser('ueyama');
  // private readonly Tanaka = this.getUser('tanaka');
  // private readonly Mukai = this.getUser('mukai');

  private get AllUsers() {
    return this.state.allUsers;
  }

  constructor(props: TurnPickerProps) {
    super(props);
    const { turnId, stage } = props.verb;
    let turn = turnId;
    if (stage === 8) {
      turn = this.props.stageGroups.stage8group.userId;
    }
    if (stage === 9) {
      turn = this.props.stageGroups.stage9group.userId;
    }
    this.state = {
      turnId: turn,
      s5AssistantId: props.verb.s5AssistantId,
      s8AssistantId: props.verb.s8AssistantId,
      s9AssistantId: props.verb.s9AssistantId,
      allUsers: []
    };
    this.handleS5AssistantChange = this.handleS5AssistantChange.bind(this);
    this.handleS8AssistantChange = this.handleS8AssistantChange.bind(this);
    this.handleS9AssistantChange = this.handleS9AssistantChange.bind(this);
  }

  async componentDidMount() {
    const allUsers = await DataService.getAllUsers();
    this.setState({ allUsers });
    // console.log(`${JSON.stringify(this.allUsers)}`)
  }

  public get availableTurns(): User[] {
    return this.getTurnOptions();
  }

  private getTurnOptions(): User[] {
    const turnOptions = [] as User[];
    const verb = this.props.verb;
    const currentStage = this.props.verb.stage;
    if (currentStage === 3 && this.props.jpMembers) {
      const assignee = this.props.jpMembers.find(
        (m) => m.userId === verb.jpAssigneeId
      );
      if (assignee) {
        turnOptions.push(assignee);
      }
      const checker = this.props.jpManagers.find(
        (m) => m.userId === verb.jpCheckerId
      );
      if (checker && assignee && checker.userId !== assignee.userId) {
        turnOptions.push(checker);
      }

      if (verb.turnId == null && assignee) {
        verb.turnId = assignee.userId;
      }
    }

    if (currentStage === 4) {
      const user = this.getUser('hayashishita');
      turnOptions.push(user);
      verb.turnId = user.userId;
    }

    if (currentStage === 5) {
      turnOptions.push(this.props.stageGroups.stage5group);
      verb.turnId = this.props.stageGroups.stage5group.userId;
    }

    if (currentStage === 7 && this.props.enMembers) {
      const assignee = this.props.enMembers.find(
        (m) => m.userId === verb.enAssigneeId
      );
      if (assignee) {
        turnOptions.push(assignee);
      }
      const checker = this.props.enManagers.find(
        (m) => m.userId === verb.enCheckerId
      );
      if (checker && assignee && checker.userId !== assignee.userId) {
        turnOptions.push(checker);
      }

      if (verb.turnId == null && assignee) {
        verb.turnId = assignee.userId;
      }
    }

    if (currentStage === 8) {
      turnOptions.push(this.props.stageGroups.stage8group);
      verb.turnId = this.props.stageGroups.stage8group.userId; //* S8Group is the only option.
    }

    if (currentStage === 9) {
      turnOptions.push(this.props.stageGroups.stage9group);
      verb.turnId = this.props.stageGroups.stage9group.userId; //* S9Group is the only option.
    }

    return turnOptions;
  }

  getUser(username: string): User {
    const user = this.AllUsers.find((x) => x.username === username);
    if (user) {
      return user;
    } else {
      throw new Error(`getUser() cannot find user '${username}'.`);
    }
  }

  handleS5AssistantChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState(
      {
        s5AssistantId: event.target.value
      },
      () => this.props.onS5AssistantChange(this.state.s5AssistantId || '')
    );
  }

  handleS8AssistantChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState(
      {
        s8AssistantId: event.target.value
      },
      () => this.props.onS8AssistantChange(this.state.s8AssistantId || '')
    );
  }

  handleS9AssistantChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState(
      {
        s9AssistantId: event.target.value
      },
      () => this.props.onS9AssistantChange(this.state.s9AssistantId || '')
    );
  }

  render() {
    if (this.state.allUsers.length === 0) {
      return <p>Loading...</p>;
    }

    const Ueyama = this.getUser('ueyama');
    const Tanaka = this.getUser('tanaka');
    const Mukai = this.getUser('mukai');
    const Ohsaki = this.getUser('ohsaki');
    const Miyoshi = this.getUser('miyoshi');
    const Mikami = this.getUser('mikami');
    const Takai = this.getUser('takai');

    const users = this.getTurnOptions();
    const options = users.map((user) => (
      <option key={user.userId} value={user.userId}>
        {user.displayName}
      </option>
    ));
    return (
      <>
        <select
          className="uk-select uk-form-width-medium"
          value={this.state.turnId}
          disabled={this.props.disabled}
          onChange={(e) => {
            this.setState({ turnId: e.target.value }, () =>
              this.props.onTurnChange(this.state.turnId)
            );
          }}
        >
          {options}
        </select>
        {this.props.verb.stage === 5 && (
          <div>
            <label>りんげ、</label>&nbsp;
            <label>上山、</label>&nbsp;
            <label>
              <input
                className="uk-radio"
                type="radio"
                name="s5-assistant"
                value={Tanaka.userId}
                checked={
                  !this.state.s5AssistantId ||
                  this.state.s5AssistantId === Tanaka.userId
                }
                onChange={(e) => this.handleS5AssistantChange(e)}
              />
              &nbsp;田中、
            </label>
            &nbsp;
            <label>
              <input
                className="uk-radio"
                type="radio"
                name="s5-assistant"
                value={Mukai.userId}
                checked={this.state.s5AssistantId === Mukai.userId}
                onChange={this.handleS5AssistantChange}
              />
              &nbsp;向井、
            </label>
            <label>
              <input
                className="uk-radio"
                type="radio"
                name="s5-assistant"
                value={Ohsaki.userId}
                checked={this.state.s5AssistantId === Ohsaki.userId}
                onChange={this.handleS5AssistantChange}
              />
              &nbsp;大崎、
            </label>
            <label>
              <input
                className="uk-radio"
                type="radio"
                name="s5-assistant"
                value={Miyoshi.userId}
                checked={this.state.s5AssistantId === Miyoshi.userId}
                onChange={this.handleS5AssistantChange}
              />
              &nbsp;三好、
            </label>
            <label>
              <input
                className="uk-radio"
                type="radio"
                name="s5-assistant"
                value={Mikami.userId}
                checked={this.state.s5AssistantId === Mikami.userId}
                onChange={this.handleS5AssistantChange}
              />
              &nbsp;三上、
            </label>
            <label>
              <input
                className="uk-radio"
                type="radio"
                name="s5-assistant"
                value={Takai.userId}
                checked={this.state.s5AssistantId === Takai.userId}
                onChange={this.handleS5AssistantChange}
              />
              &nbsp;高井
            </label>
          </div>
        )}
        {this.props.verb.stage === 8 &&
          this.state.turnId === this.getUser('stage8group').userId && (
            <div>
              <label>りんげ、</label>&nbsp;
              <label>
                <input
                  className="uk-radio"
                  type="radio"
                  name="s8-assistant"
                  value={Ueyama.userId}
                  checked={
                    !this.state.s8AssistantId ||
                    this.state.s8AssistantId === Ueyama.userId
                  }
                  onChange={(e) => this.handleS8AssistantChange(e)}
                />
                &nbsp;上山
              </label>
            </div>
          )}
        {this.props.verb.stage === 9 &&
          this.state.turnId === this.getUser('stage9group').userId && (
            <div>
              <label>りんげ、</label>&nbsp;
              <label>
                <input
                  className="uk-radio"
                  type="radio"
                  name="s5-assistant"
                  value={Ueyama.userId}
                  checked={
                    !this.state.s9AssistantId ||
                    this.state.s9AssistantId === Ueyama.userId
                  }
                  onChange={(e) => this.handleS9AssistantChange(e)}
                />
                &nbsp;上山、
              </label>
              &nbsp;
              <label>
                <input
                  className="uk-radio"
                  type="radio"
                  name="s9-assistant"
                  value={Mukai.userId}
                  checked={this.state.s9AssistantId === Mukai.userId}
                  onChange={this.handleS9AssistantChange}
                />
                &nbsp;向井
              </label>
            </div>
          )}
      </>
    );
  }
}

export default TurnPicker;
