import { PageState, Paginator } from 'primereact/paginator';
import React, { CSSProperties, Component, Fragment, MouseEvent } from 'react';

import AssigneeSelector from './AssigneeSelector';
import CreateNewVerbDialog from './CreateNewVerbDialog';
import DataService from '../services/data.service';
import { DisplayMode } from '../models/DisplayMode';
import DuplicateVerbDialog from './DuplicateVerbDialog';
import { Example } from '../models/Example';
import { Message } from '../models/Message';
import Overlay from './Overlay';
import { StageGroups } from '../models/StageGroups';
import { User } from '../models/User';
import { Verb } from '../models/Verb';
import VerbEditDialog from './VerbEditDialog';
import VerbEditor from './VerbEditor';
import _ from 'lodash';
import dataService from '../services/data.service';

const UIkit = require('uikit');

interface VerbTableProps {
  username: string;
  displayMode: DisplayMode;
  searchWord: string;
  filterStage: number;
  filterUserId: string;
  dataUploaded: boolean;
  onDataRefresh: () => void;
}

interface VerbTableState {
  first: number;
  numItemsPerPage: number;
  displayStage: number; // 0: all, 2 or 6
  numVerbs: number;
  verbs: Verb[];
  selectedVerb: Verb | null;
  jpMembers: User[];
  jpManagers: User[];
  enMembers: User[];
  enManagers: User[];
  stageGroups: StageGroups;
  addingVerb: boolean;
  duplicatedVerb: Verb | null;
  loading: boolean;
}

class VerbTable extends Component<VerbTableProps, VerbTableState> {
  constructor(props: any) {
    super(props);
    this.state = {
      first: 0,
      numItemsPerPage: 20,
      displayStage: 0,
      numVerbs: 0,
      verbs: [],
      selectedVerb: null,
      jpMembers: [],
      jpManagers: [],
      enMembers: [],
      enManagers: [],
      stageGroups: {} as StageGroups,
      addingVerb: false,
      duplicatedVerb: null,
      loading: true
    };
    this.onFsdChange.bind(this);
  }

  async componentDidMount() {
    const jpMembers = await DataService.getMembers(1);
    const jpManagers = await DataService.getManagers(1);
    const enMembers = await DataService.getMembers(2);
    const enManagers = await DataService.getManagers(2);
    const stageGroups = await DataService.getStageGroups();
    this.setState({
      jpMembers,
      jpManagers,
      enMembers,
      enManagers,
      stageGroups
    });

    await this.loadVerbs(false);
  }

  async componentDidUpdate(
    prevProps: VerbTableProps,
    prevState: VerbTableState
  ) {
    if (
      prevProps.displayMode !== this.props.displayMode ||
      prevProps.searchWord !== this.props.searchWord ||
      prevProps.filterStage !== this.props.filterStage ||
      prevProps.filterUserId !== this.props.filterUserId ||
      (prevProps.dataUploaded !== this.props.dataUploaded &&
        this.props.dataUploaded)
    ) {
      await this.loadVerbs(true);
      this.props.onDataRefresh();
    }
  }

  async loadVerbs(
    moveToPageOne: boolean,
    cb: (verbs: Verb[]) => void = () => {
      this.setState({ loading: false });
    }
  ) {
    this.setState({ loading: true }, async () => {
      console.log(`loading: ${this.state.loading}`);
      const displayStage =
        this.props.displayMode === 'stage2'
          ? 2
          : this.props.displayMode === 'stage6'
          ? 6
          : this.props.filterStage;
      const first = moveToPageOne ? 0 : this.state.first;
      const verbsResponse = await DataService.getVerbs(
        Math.floor(first / this.state.numItemsPerPage),
        this.state.numItemsPerPage,
        displayStage,
        this.props.filterUserId,
        this.props.searchWord
      );
      this.setState(
        {
          first,
          verbs: _.cloneDeep(verbsResponse.verbs),
          numVerbs: verbsResponse.numVerbs
        },
        () => cb(this.state.verbs)
      );
      this.props.onDataRefresh();
      console.log(`loading: ${this.state.loading}`);
    });
  }

  onPageChange = async (e: PageState) => {
    this.setState({ first: e.first }, async () => await this.loadVerbs(false));
  };

  private openEditor(event: MouseEvent<HTMLAnchorElement>, verb: Verb) {
    // event.preventDefault();
    this.setState({ selectedVerb: verb }, () =>
      UIkit.modal('#editor', {
        'bg-close': false,
        stack: true,
        modal: false
      }).show()
    );
  }

  private onAssigneeSave = async (
    verb: Verb,
    assignedMemberId: string,
    assignedManagerId: string
  ) => {
    if (verb.stage === 2) {
      verb.jpAssigneeId = assignedMemberId;
      verb.jpCheckerId = assignedManagerId;
    } else if (verb.stage === 6) {
      verb.enAssigneeId = assignedMemberId;
      verb.enCheckerId = assignedManagerId;
    } else {
      throw new Error(
        `onAssigneeSave cannot assign members at stage ${verb.stage}.`
      );
    }
    const previousStage = verb.stage;
    verb.stage = verb.stage + 1;
    await dataService.updateVerb(verb, previousStage);
    await this.loadVerbs(false);
  };

  private onSaveSettings = async (verb: Verb) => {
    await dataService.updateVerb(verb);
    await this.loadVerbs(false, (verbs) => {
      const selectedVerb = verbs.find((v) => v.id === verb.id);
      if (selectedVerb) {
        this.setState({ selectedVerb });
      }
      this.setState({ loading: false });
    });
  };

  private onChangeStage = async (stage: number) => {
    if (this.state.selectedVerb) {
      const verb = this.state.selectedVerb;
      const previousStage = verb.stage;
      verb.stage = stage;
      await dataService.updateVerb(verb, previousStage);
      await this.loadVerbs(false);
      this.setState({ selectedVerb: null }, () =>
        UIkit.modal('#editor', {
          'bg-close': false,
          stack: true,
          modal: false
        }).hide()
      );
    }
  };

  private onCloseEditor = async () => {
    this.setState({ selectedVerb: null });
    await this.loadVerbs(false, () => this.setState({ loading: false }));
  };

  render() {
    const header = (
      <thead>
        <tr>
          <th className="uk-table-expand">Verb</th>
          <th className="uk-table-shrink">Stage</th>
          <th>Structure</th>
          <th>Definition</th>
          <th>Representative Example</th>
          <th className="uk-table-expand">
            <a
              href="#"
              className="uk-icon-button uk-button-primary"
              uk-icon="icon: plus"
              onClick={this.createNewVerb}
            ></a>
          </th>
        </tr>
      </thead>
    );
    const rows =
      this.state.verbs && this.state.verbs.length > 0 ? (
        this.state.verbs.map((v) => {
          const editButtonStyle: CSSProperties =
            v.stage === 2 || v.stage === 6
              ? { visibility: 'hidden' }
              : { visibility: 'visible' };
          return (
            <Fragment key={v.id}>
              <tr>
                <td>{v.value}</td>
                <td>{v.stage}</td>
                <td>{v.structure}</td>
                <td>{v.fullSentenceDefinition}</td>
                <td>{v.representativeExample}</td>
                <td>
                  <a
                    href="#"
                    className="uk-icon-button"
                    uk-icon="icon: pencil"
                    style={editButtonStyle}
                    onClick={(e) => this.openEditor(e, v)}
                  ></a>
                  <a
                    href="#"
                    className="uk-icon-button"
                    uk-icon="icon: copy"
                    onClick={() => this.showDuplicateVerbDialog(v)}
                  ></a>
                  <a
                    href="#"
                    className="uk-icon-button"
                    uk-icon="icon: trash"
                    onClick={async () => await this.onDeleteVerb(v)}
                  ></a>
                </td>
              </tr>
              {this.props.displayMode === 'stage2' && v.stage === 2 && (
                <tr style={{ borderTop: 'none' }}>
                  <td colSpan={6} style={{ paddingTop: 0 }}>
                    <AssigneeSelector
                      verb={v}
                      members={this.state.jpMembers}
                      managers={this.state.jpManagers}
                      onAssigneeSave={this.onAssigneeSave}
                    />
                  </td>
                </tr>
              )}
              {this.props.displayMode === 'stage6' && v.stage === 6 && (
                <tr style={{ borderTop: 'none' }}>
                  <td colSpan={6} style={{ paddingTop: 0 }}>
                    <AssigneeSelector
                      verb={v}
                      members={this.state.enMembers}
                      managers={this.state.enManagers}
                      onAssigneeSave={this.onAssigneeSave}
                    />
                  </td>
                </tr>
              )}
            </Fragment>
          );
        })
      ) : (
        <tr>
          <td colSpan={6}>No entries found</td>
        </tr>
      );

    return (
      <Fragment>
        <Paginator
          first={this.state.first}
          rows={this.state.numItemsPerPage}
          totalRecords={this.state.numVerbs}
          onPageChange={this.onPageChange}
          leftContent={<span>{this.state.numVerbs} verbs</span>}
        ></Paginator>
        <table className="uk-table uk-table-divider uk-table-hover">
          {header}
          <tbody>{rows}</tbody>
        </table>
        <Paginator
          first={this.state.first}
          rows={this.state.numItemsPerPage}
          totalRecords={this.state.numVerbs}
          onPageChange={this.onPageChange}
          leftContent={<span>{this.state.numVerbs} verbs</span>}
        ></Paginator>
        {this.state.loading && (
          <Fragment>
            <Overlay spinner={true} />
          </Fragment>
        )}
        {this.state.selectedVerb !== null && (
          <div>
            <VerbEditDialog
              username={this.props.username}
              selectedVerb={this.state.selectedVerb}
              jpMembers={this.state.jpMembers}
              jpManagers={this.state.jpManagers}
              enMembers={this.state.enMembers}
              stageGroups={this.state.stageGroups}
              enManagers={this.state.enManagers}
              onClose={this.onCloseEditor}
              onSaveSettings={this.onSaveSettings}
              onChangeStage={this.onChangeStage}
              onFsdChange={(value) => this.onFsdChange(value)}
            />
          </div>
        )}
        {this.state.addingVerb && (
          <div>
            <CreateNewVerbDialog
              onClose={this.closeNewVerbDialog}
              onSave={this.onSaveNewVerb}
            />
          </div>
        )}
        {this.state.duplicatedVerb && (
          <div>
            <DuplicateVerbDialog
              verb={this.state.duplicatedVerb}
              onClose={() =>
                this.closeDialog(
                  { duplicatedVerb: null } as VerbTableState,
                  '#duplicate-verb-dialog'
                )
              }
              onSave={this.onDuplicateVerb}
            />
          </div>
        )}
      </Fragment>
    );
  }

  private createNewVerb = () => {
    this.setState(
      {
        addingVerb: true
      },
      () =>
        UIkit.modal('#create-new-verb-dialog', {
          'bg-close': false,
          stack: true,
          modal: false
        }).show()
    );
  };

  private closeNewVerbDialog = () => {
    this.setState(
      {
        addingVerb: false
      },
      () =>
        UIkit.modal('#create-new-verb-dialog', {
          'bg-close': false,
          stack: true,
          modal: false
        }).hide()
    );
  };

  private onSaveNewVerb = async (value: string) => {
    const page = await dataService.addVerb(value);
    this.closeNewVerbDialog();
    await this.loadVerbs(false);
  };

  private onDuplicateVerb = async (
    verb: Verb,
    jpExamples: Example[],
    enExamples: Example[]
  ) => {
    await dataService.duplicateVerb(verb, jpExamples, enExamples);
    this.setState({
      duplicatedVerb: null
    });
    await this.loadVerbs(false);
  };

  private onDeleteVerb = async (verb: Verb) => {
    UIkit.modal
      .confirm(
        `Are you sure you want to delete <strong>${verb.value}</strong>?`
      )
      .then(
        async () => {
          console.log('Confirmed.');
          verb.status = 1;
          await dataService.updateVerb(verb);
          await this.loadVerbs(false);
        },
        () => {
          console.log('Rejected.');
        }
      );
  };

  private showDuplicateVerbDialog = (verb: Verb) => {
    this.setState(
      {
        duplicatedVerb: verb
      },
      () =>
        UIkit.modal('#duplicate-verb-dialog', {
          'bg-close': false,
          stack: true,
          modal: false
        }).show()
    );
  };

  private closeDialog = (state: VerbTableState, dialogId: string) => {
    this.setState(state, () =>
      UIkit.modal(dialogId, {
        'bg-close': false,
        stack: true,
        modal: false
      }).hide()
    );
  };

  private onFsdChange(value: string) {
    if (this.state.selectedVerb === null) {
      return;
    }
    const verb = _.cloneDeep(this.state.selectedVerb);
    verb.fullSentenceDefinition = value;
    this.setState({ selectedVerb: verb });
  }
}

export default VerbTable;
