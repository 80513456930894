import React, { Component, useEffect, useState } from 'react';
import DataService from '../services/data.service';
import UserHistory from '../models/UserHistory';
import { User } from '../models/User';
import HistoryTable from './HistoryTable';
import { LANG } from '../models/Language';

interface HistoryDialogProps {
  onClose: () => void;
}

const HistoryDialog = (props: HistoryDialogProps) => {
  const [history, setHistory] = useState<UserHistory[]>([]);
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await getUsers();
    await getUserHistory();
  }

  const getUsers = async () => {
    const users = [];
    users.push(...await DataService.getAdmins());
    users.push(...await DataService.getMembers(LANG.jp, false, false));
    users.push(...await DataService.getMembers(LANG.en, false, false));
    setUsers(users);
  }

  const getUserHistory = async () => {
    const history = await DataService.getUserHistory();
    setHistory(history);
    console.debug(JSON.stringify(history));
  }

  return (
    <div id="history-dialog" className="uk-modal" uk-modal='{ "bg-close": false }'>
      <div className="uk-modal-dialog uk-width-3-4">
        <button
          className="uk-modal-close-default"
          type="button"
          uk-close=""
          onClick={ () => props.onClose() }
        >
        </button>
        <div className="uk-modal-header">
            <div className="uk-modal-title">
              貢献度チャート
            </div>
        </div>
        <div className="uk-modal-body">
          <HistoryTable
            users={users}
            history={history}
          />
        </div>
        <div className="uk-modal-footer uk-text-right">
          <button
            className="uk-button"
            type="button"
            onClick={ () => props.onClose() }
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default HistoryDialog;
