import React, { Component } from 'react';
import VerbEditor from './VerbEditor';
import { Verb } from '../models/Verb';
import { User } from '../models/User';
import dataService from '../services/data.service';
import { StageGroups } from '../models/StageGroups';
import Config from '../config';

interface CreateNewVerbDialogProps {
  onClose: () => void;
  onSave: (verb: string) => void;
}

interface CreateNewVerbDialogState {
  verb: string;
}

class CreateNewVerbDialog
  extends Component<CreateNewVerbDialogProps, CreateNewVerbDialogState> {
  private  nameInput: HTMLInputElement | null = null;

  constructor(props: CreateNewVerbDialogProps) {
    super(props);
    this.state = {
      verb: ''
    };
  }

  componentDidMount() {
    if (this.nameInput) {
      this.nameInput.setAttribute('autofocus', 'true');
    }
  }

  render() {
    return (
    <div id="create-new-verb-dialog" className="uk-modal" uk-modal='{ "bg-close": false }'>
      <div className="uk-modal-dialog uk-width-1-4">
        <button
          className="uk-modal-close-default"
          type="button"
          uk-close=""
          onClick={ () => this.props.onClose() }
        >
        </button>
        <div className="uk-modal-header">
            <div className="uk-modal-title">
              Create new verb
            </div>
        </div>
        <div className="uk-modal-body">
          <label className="uk-form-label">Verb</label>
          <input
            type="text"
            className="uk-input"
            value={this.state.verb}
            name="username"
            ref={ input => this.nameInput = input }
            onChange={ e => {
              this.setState({ verb: e.target.value });
            }}
          />
        </div>
        <div className="uk-modal-footer uk-text-right">
          <button
            className="uk-button"
            type="button"
            onClick={ () => this.props.onClose() }
          >
            Cancel
          </button>
          <button
            className="uk-button uk-button-primary"
            type="button"
            disabled={!this.state.verb.trim()}
            onClick={ () => this.onSave() }
          >
            Save
          </button>
        </div>
      </div>
    </div>
    );
  }

  private onSave = () => {
    console.log('save ' + this.state.verb);
    this.props.onSave(this.state.verb);
 }
}

export default CreateNewVerbDialog;
