import './Settings.css';

import {
  Conjugation,
  ConjugationOptions,
  Makeup,
  MakeupOptions,
  Origin,
  OriginOptions,
  Uses,
  UsesOptions,
  Verb
} from '../models/Verb';
import React, { ChangeEvent, Component, Fragment } from 'react';
import VerbPicker, { VerbOption } from './VerbPicker';

import Constants from '../constants';
import { Message } from '../models/Message';
import MessagePanel from './MessagePanel';
import { OtherInfoPanel } from './OtherInfoPanel';
import TextInput from './TextInput';
import { User } from '../models/User';
import { ValueType } from 'react-select/lib/types';
import { VerbDropdown } from './VerbDropdown';

const UIkit = require('uikit');

interface SettingsProps {
  username: string;
  verb: Verb;
  finalFsd: string;
  jpMembers: User[];
  jpManagers: User[];
  enMembers: User[];
  enManagers: User[];
  messages: Message[];
  onSaveMessage: (content: string) => void;
  onDeleteMessage: (message: Message) => void;
  onResolveMessage: (id: string) => void;
  onUnresolveMessage: (id: string) => void;
  onSaveSettings: (verb: Verb) => Promise<void>;
}

interface SettingsState {
  value: string;
  localIndex: number;
  links1: string[]; // 自他対応 array of verb IDs
  links2: string[]; // 同義異格配列 array of verb IDs
  links3: string[]; // 参照項目 array of verb IDs
  structure: string;
  englishIndex: string;
  representativeExample: string;
  noteForExampleAuthor: string;
  generalNotes: string;
  jpAssigneeId: string;
  jpCheckerId: string;
  enAssigneeId: string;
  enCheckerId: string;
  turnId: string;
  saving: boolean;
  stem: string;
  conjugation: Conjugation;
  origin: Origin;
  makeup: Makeup;
  uses: Uses;
  indexExample: string;
  otherInfo: number;
}

class Settings extends Component<SettingsProps, SettingsState> {
  private get fsd() {
    return this.props.finalFsd;
  }

  constructor(props: SettingsProps) {
    super(props);
    console.debug(JSON.stringify(props.verb));
    this.state = {
      ...props.verb,
      saving: false
    };
  }

  // componentDidMount() {
  //   this.setState({
  //     verb: this.props.verb,
  //     value: this.props.verb.value
  //   });
  // }

  onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    } as any);
  };

  onSaveSettings = async () => {
    // stitch up all properties and call parent's onSaveSettings
    const verb = {
      ...this.props.verb,
      ...this.state,
      messages: this.props.messages
    };
    verb.links1String = verb.links1?.join(',') || '';
    verb.links2String = verb.links2?.join(',') || '';
    verb.links3String = verb.links3?.join(',') || '';
    await this.props.onSaveSettings(verb);
  };

  render() {
    const { verb } = this.props;
    return (
      <Fragment>
        <div className="uk-inline">
          <div className="uk-inline uk-margin">
            <button
              className="uk-button uk-button-primary"
              disabled={this.state.saving}
              onClick={async (e) => {
                e.preventDefault();
                this.setState({ saving: true }, async () => {
                  await this.onSaveSettings();
                  this.setState({ saving: false });
                });
              }}
            >
              Save Settings
            </button>
          </div>
        </div>
        <div className="form-group uk-margin">
          <div className="settings-row">
            <div className="settings-row-item">
              <label className="uk-form-label">動詞 (Verb)</label>
              <input
                className="uk-input"
                value={this.state.value || ''}
                onChange={(e) => this.setState({ value: e.target.value })}
              />
            </div>
            <div className="settings-row-item">
              <label className="uk-form-label">表示順位 (Partial order)</label>
              <input
                type="number"
                min={0}
                className="uk-input"
                value={
                  this.state.localIndex != null ? this.state.localIndex : ''
                }
                onChange={(e) => {
                  if (e.target.value !== '') {
                    this.setState({ localIndex: +e.target.value });
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="form-group uk-margin">
          <div className="settings-row">
            <div className="settings-row-item">
              <label className="uk-form-label">語幹 (Stem)</label>
              <input
                className="uk-input"
                value={this.state.stem ?? ''}
                onChange={(e) => this.setState({ stem: e.target.value })}
              />
            </div>
            <div className="settings-row-item">
              <label className="uk-form-label">
                英語インデックス (English index)
              </label>
              <input
                className="uk-input"
                value={
                  this.state.englishIndex != null ? this.state.englishIndex : ''
                }
                onChange={(e) =>
                  this.setState({ englishIndex: e.target.value })
                }
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="uk-inline uk-grid-medium uk-child-width-auto uk-grid">
            <div className="uk-inline">
              <label className="uk-form-label">活用 (Conjugation)</label>
              <VerbDropdown<Conjugation>
                options={ConjugationOptions}
                selected={this.props.verb.conjugation ?? ''}
                width={150}
                onChange={(value) => this.setState({ conjugation: value })}
              />
            </div>
            <div className="uk-inline">
              <label className="uk-form-label">語種 (Origin)</label>
              <VerbDropdown<Origin>
                options={OriginOptions}
                selected={this.props.verb.origin ?? ''}
                width={150}
                onChange={(value) => this.setState({ origin: value })}
              />
            </div>
            <div className="uk-inline">
              <label className="uk-form-label">語構成 (Makeup)</label>
              <VerbDropdown<Makeup>
                options={MakeupOptions}
                selected={
                  verb.makeup === 'Idiom' || verb.makeup === 'Adv'
                    ? ''
                    : this.props.verb.makeup ?? ''
                }
                width={220}
                onChange={(value) => this.setState({ makeup: value })}
              />
            </div>
            <div className="uk-inline">
              <label className="uk-form-label">待遇 (Uses)</label>
              <VerbDropdown<Uses>
                options={UsesOptions}
                selected={this.props.verb.uses ?? ''}
                width={150}
                onChange={(value) => this.setState({ uses: value })}
              />
            </div>
          </div>
        </div>
        <OtherInfoPanel
          value={this.state.otherInfo}
          onChange={(value) => this.setState({ otherInfo: value })}
        />
        <div className="form-group">
          <label className="uk-form-label">
            自他対応 (Intransitive-transitive correspondence)
          </label>
          <VerbPicker
            targetId={this.props.verb.id}
            selectedIds={this.state.links1}
            onChange={(newValue: ValueType<VerbOption>) => {
              if (newValue && newValue instanceof Array) {
                this.setState({
                  links1: newValue.map((option) => option.value)
                });
              }
              console.debug(JSON.stringify(newValue));
            }}
          />
        </div>
        <div className="form-group">
          <label className="uk-form-label">
            同義異格配列 (Same meaning different particles)
          </label>
          <VerbPicker
            targetId={this.props.verb.id}
            selectedIds={this.state.links2}
            onChange={(newValue: ValueType<VerbOption>) => {
              if (newValue && newValue instanceof Array) {
                this.setState({
                  links2: newValue.map((option) => option.value)
                });
              }
              console.debug(`Settings.render() - ${JSON.stringify(newValue)}`);
            }}
          />
        </div>
        <div className="form-group">
          <label className="uk-form-label">
            参照項目 (Other corresponding items)
          </label>
          <VerbPicker
            targetId={this.props.verb.id}
            selectedIds={this.state.links3}
            onChange={(newValue: ValueType<VerbOption>) => {
              if (newValue && newValue instanceof Array) {
                this.setState({
                  links3: newValue.map((option) => option.value)
                });
              }
              console.debug(`Settings.render() - ${JSON.stringify(newValue)}`);
            }}
          />
        </div>
        <div className="form-group">
          <label className="uk-form-label">見出し用例文 (Index examples)</label>
          <input
            className="uk-input"
            value={this.state.indexExample || ''}
            onChange={(e) => this.setState({ indexExample: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label className="uk-form-label">文型 (Structure)</label>
          <input
            className="uk-input"
            value={this.state.structure || ''}
            onChange={(e) => this.setState({ structure: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label className="uk-form-label">定義 (Definition)</label>
          <div className="col-sm-4">
            {this.props.verb.fullSentenceDefinition || '-----'}
          </div>
        </div>
        <div className="form-group">
          <label className="uk-form-label">備考 (Note)</label>
          <div className="col-sm-4">
            {this.props.verb.noteForReferenceBook || '-----'}
          </div>
        </div>
        <div style={{ height: 32 }}></div>
        <div className="form-group">
          <label className="uk-form-label">
            典型例文 (Representative examples)
          </label>
          <textarea
            className="uk-textarea"
            value={this.state.representativeExample || ''}
            onChange={(e) =>
              this.setState({ representativeExample: e.target.value })
            }
          ></textarea>
        </div>
        <div className="form-group">
          <label className="uk-form-label">
            提示文・定義作成に関する注意事項 (For the preparation of the
            definition and examples)
          </label>
          <textarea
            className="uk-input"
            value={this.state.noteForExampleAuthor || ''}
            onChange={(e) =>
              this.setState({ noteForExampleAuthor: e.target.value })
            }
          ></textarea>
        </div>
        <div className="form-group">
          <label className="uk-form-label">備考用のメモ (Memo)</label>
          <textarea
            className="uk-input"
            value={this.state.generalNotes || ''}
            onChange={(e) => this.setState({ generalNotes: e.target.value })}
          ></textarea>
        </div>
        <div className="form-group">
          <label className="uk-form-label">
            他のStage担当者へのメッセージ (Messages for people responsible for
            other stages)
          </label>
          <MessagePanel
            messages={this.props.messages || []}
            onSaveMessage={this.props.onSaveMessage}
            onResolveMessage={this.props.onResolveMessage}
            onUnresolveMessage={this.props.onUnresolveMessage}
            onDeleteMessage={this.props.onDeleteMessage}
          ></MessagePanel>
        </div>
        <div className="uk-inline uk-margin uk-grid-medium uk-child-width-auto uk-grid">
          <div className="uk-inline">
            <label className="uk-form-label">提示文作成者</label>
            <select
              className="uk-select"
              value={this.state.jpAssigneeId || Constants.emptyUuid}
              onChange={(e) => {
                this.setState({ jpAssigneeId: e.target.value });
                if (verb.stage === 3) {
                  this.setState({ turnId: e.target.value });
                }
              }}
            >
              {this.props.jpMembers.map((m) => (
                <option value={m.userId} key={m.userId}>
                  {m.displayName}
                </option>
              ))}
            </select>
          </div>
          <div className="uk-inline">
            <label className="uk-form-label">提示文作成確認者</label>
            <select
              className="uk-select"
              value={this.state.jpCheckerId || Constants.emptyUuid}
              onChange={(e) => this.setState({ jpCheckerId: e.target.value })}
            >
              {this.props.jpManagers.map((m) => (
                <option value={m.userId} key={m.userId}>
                  {m.displayName}
                </option>
              ))}
            </select>
          </div>
          <div className="uk-inline">
            <label className="uk-form-label">英語文改善者</label>
            <select
              className="uk-select"
              value={this.state.enAssigneeId || Constants.emptyUuid}
              onChange={(e) => {
                this.setState({ enAssigneeId: e.target.value });
                if (verb.stage === 7) {
                  this.setState({ turnId: e.target.value });
                }
              }}
            >
              {this.props.enMembers.map((m) => (
                <option value={m.userId} key={m.userId}>
                  {m.displayName}
                </option>
              ))}
            </select>
          </div>
          <div className="uk-inline">
            <label className="uk-form-label">英語文改善確認者</label>
            <select
              className="uk-select"
              value={this.state.enCheckerId || Constants.emptyUuid}
              onChange={(e) => this.setState({ enCheckerId: e.target.value })}
            >
              {this.props.enManagers.map((m) => (
                <option value={m.userId} key={m.userId}>
                  {m.displayName}
                </option>
              ))}
            </select>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Settings;
