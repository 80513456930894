import React, { ChangeEvent, Component } from 'react';

import { User } from '../models/User';
import { Verb } from '../models/Verb';
import constants from '../constants';
import dataService from '../services/data.service';

interface AssigneeSelectorProps {
  verb: Verb;
  members: User[];
  managers: User[];
  onAssigneeSave: (
    verb: Verb,
    assignedMemberId: string,
    assignedManagerId: string
  ) => void;
}

interface AssigneeSelectorState {
  assignedMemberId: string;
  assignedManagerId: string;
}

class AssigneeSelector extends Component<
  AssigneeSelectorProps,
  AssigneeSelectorState
> {
  constructor(props: AssigneeSelectorProps) {
    super(props);
    const verb = this.props.verb;
    const assignedMemberId =
      verb.stage === 2 ? verb.jpAssigneeId : verb.enAssigneeId;
    const assignedManagerId =
      verb.stage === 2 ? verb.jpCheckerId : verb.enCheckerId;
    this.state = {
      assignedMemberId: assignedMemberId || constants.emptyUuid,
      assignedManagerId: assignedManagerId || constants.emptyUuid
    };
  }

  onMemeberChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      assignedMemberId: e.target.value
    });
  };

  onManagerChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      assignedManagerId: e.target.value
    });
  };

  render() {
    const { members, managers } = this.props;
    const memberButtons = members.map((m) => (
      <label key={m.userId}>
        <input
          className="uk-radio"
          type="radio"
          name={`member-${this.props.verb.id}`}
          value={m.userId}
          checked={this.state.assignedMemberId === m.userId}
          onChange={this.onMemeberChange}
        />
        &nbsp;{m.displayName}
      </label>
    ));
    const managerButtons = managers.map((m) => (
      <label key={m.userId}>
        <input
          className="uk-radio"
          type="radio"
          name={`manager-${this.props.verb.id}`}
          value={m.userId}
          checked={this.state.assignedManagerId === m.userId}
          onChange={this.onManagerChange}
        />
        &nbsp;{m.displayName}
      </label>
    ));
    return (
      <span className="uk-inline uk-margin uk-grid-small uk-child-width-auto uk-grid">
        <span className="uk-badge uk-margin-left">作成</span>
        {memberButtons}
        &nbsp;
        <label className="uk-badge uk-margin-left">確認</label>
        {managerButtons}
        <button
          className="uk-button uk-button-primary uk-button-small uk-margin-left"
          disabled={
            this.state.assignedMemberId === constants.emptyUuid ||
            this.state.assignedManagerId === constants.emptyUuid
          }
          onClick={(e) => {
            const verb = JSON.parse(JSON.stringify(this.props.verb)) as Verb;
            this.props.onAssigneeSave(
              verb,
              this.state.assignedMemberId,
              this.state.assignedManagerId
            );
          }}
        >
          Save
        </button>
      </span>
    );
  }
}

export default AssigneeSelector;
